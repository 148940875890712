/**
 * Sens
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Subscriptions } from './subscriptions';
import { SocialLink } from './socialLink';

export interface UserResponse {
  user_id: string;
  user_name: string;
  person_name?: string;
  user_bio?: string;
  user_avatar_url?: string;
  user_header_url?: string;
  email?: string;
  gender?: string;
  balance?: number;
  m1_price?: number;
  m3_price?: number;
  trial_type?: string;
  dialog_id?: string;
  likes?: number;
  posts?: number;
  faps?: number;
  videos?: number;
  images?: number;
  followers?: number;
  followings?: number;
  stream_is_active?: boolean;
  is_kyc_verified?: boolean;
  next_kyc_timestamp?: number;
  subscriptions?: Subscriptions;
  social_links?: Array<SocialLink>;
  is_blocked?: boolean;
  is_trialed?: boolean;
}
