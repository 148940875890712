import { Observable } from 'rxjs';
import {
  StreamConnectResponse,
  StreamDonateRequest,
  StreamDonateResponse,
  StreamMessageRequest,
  StreamMessageResponse,
} from '@core/models/stream.model';
import { Response } from '@core/models';

export abstract class StreamRepository {

  /**
   * @method POST
   * @param streamer_id
   * @param body
   * @return Observable<StreamDonateResponse>
   */
  public abstract postStreamDonate(streamer_id: string, body: StreamDonateRequest): Observable<StreamDonateResponse>;

  /**
   * @method POST
   * @param streamer_id
   * @param body
   * @return Observable<StreamMessageResponse>
   */
  public abstract postStreamMessage(streamer_id: string, body: StreamMessageRequest): Observable<StreamMessageResponse>;

  /**
   * @method GET
   * @param streamer_id
   * @return Observable<StreamConnectResponse>
   */
  public abstract getStreamConnect(streamer_id: string): Observable<StreamConnectResponse>;

  /**
   * @method POST
   * @return Observable<StreamConnectResponse>
   */
  public abstract postStreamStart(): Observable<StreamConnectResponse>;
  /**
   * @method POST
   * @return Observable<StreamConnectResponse>
   */
  public abstract postStreamStop(): Observable<Response>;

  public abstract postStreamComplaint(userId: string, complaintTypeId: string, comment?: string): Observable<Response>;
}
