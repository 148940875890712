import { NgModule, Optional, Self } from '@angular/core';
import {
  routerReducer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { MergedRouterStateSerializer } from './merged-route-serialzer';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { RouterEffects } from '@store/router/router.effects';

export const routerStateConfig  = {
  stateKey: 'router', // store-slice name for routing store
  serializer: MergedRouterStateSerializer,
};

@NgModule({
  imports: [
    StoreModule.forFeature(routerStateConfig.stateKey, routerReducer),
    StoreRouterConnectingModule.forRoot(routerStateConfig),
    EffectsModule.forFeature([ RouterEffects ]),
  ],
  exports: [
    StoreModule,
    StoreRouterConnectingModule,
  ],
  // providers: [
  //   {
  //     provide: RouterStateSerializer,
  //     useClass: MergedRouterStateSerializer,
  //   },
  // ],
})
export class NgrxRouterStoreModule {

  constructor(@Self() @Optional() router: Router) {
    if (router) {
      // eslint-disable-next-line no-console
      console.log('All good, NgrxRouterStoreModule');
    } else {
      // eslint-disable-next-line no-console
      console.error('NgrxRouterStoreModule must be imported in the same same level as RouterModule');
    }
  }

}
