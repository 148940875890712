import { Injectable } from '@angular/core';
import { Message } from '@core/models/message.model';
import { Toast } from 'primeng/toast';


@Injectable()
export class StreamToastService {

  private toast?: Toast;

  private readonly PREFIX = 'STREAM';

  private readonly MAX_COUNT = 3;

  private readonly TOAST_LIFE = 60 * 1000;

  private getToastId(message: Message): string {
    return `${this.PREFIX}:${message.from_user_id}`;
  }

  private toastsInclude(data: Message): boolean {
    return this.toast?.messages?.some(message => message.id === `STREAM:${data.from_user_id}`) ?? false;
  }

  private splice(): void {
    if (this.toast?.messages)  {
      this.toast.messages.splice(this.MAX_COUNT - 1);
    }
  }

  public init(toast: Toast | undefined) {
    this.toast = toast;
  }

  public updateToast(message: Message): void {
    this.splice();
    if (this.toastsInclude(message)) {
      this.closeToast(message);
    }
    this.addToast(message);
  }

  private addToast(message: Message): void {
    this.toast?.add([ {
      key: 'stream',
      id: this.getToastId(message),
      sticky: false,
      life: this.TOAST_LIFE,
      closable: false,
      data: message,
    } ]);
  }

  public closeToast(message: Message): void {
    const toastId = this.getToastId(message);
    if (this.toast?.messages) {
      this.toast.add([]); // KOSTIL Don't touch
      this.toast.messages = this.toast.messages.filter(toast => toast.id !== toastId);
    }
  }

  public closeAll(): void {
    if (this.toast?.messages) {
      this.toast?.add([]);
      this.toast.messages = [];
    }
  }
}
