// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// noinspection SpellCheckingInspection


import { VERSION } from '@data/config';

export const environment = {
  name: 'development',
  production: false,
  // base_url: 'http://localhost:4200/api/v1',
  base_url: 'https://api-sens-dev.talala.la/api/v1',
  app_name: 'Sens',
  app_version: VERSION,
  app_build: 'development',
  sentryClientDsn: 'https://679c15dca34a438f85206251e213e169@sentry-dev.talala.la/103',
  fingerprintApiKey: 'c6N1CVWHuLZ63tw71ren',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
