import { Provider } from '@angular/core';
import { APP_INITIALIZER } from '@angular/core';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';

export const BREAKPOINTS_INITIALIZER: Provider = {
  provide: APP_INITIALIZER,
  useFactory: (bs: BreakpointsService) => {
    return () => bs.init();
  },
  deps: [ BreakpointsService ],
  multi: true,
};
