import { ChangeDetectorRef, Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { OverlayService } from '@app/services/dialog/overlay.service';
import { NotificationService } from '@app/services/notifications/notification.service';

@Directive({
  selector: '[sensUpload]',
})
export class UploadDirective {

  @Output() formDataOut = new EventEmitter<FormData>();

  @Input() formDataIn?: FormData;

  @Output() preview = new EventEmitter<string[]>();

  @Output() errorUpload = new EventEmitter<string>();

  private localFormData: FormData = new FormData();

  constructor(
    private store: Store,
    private overlay: OverlayService,
    private detection: ChangeDetectorRef,
    private notification: NotificationService,
  ) { }

  private onInputImages(files: File[]): void {
    const images: File[] = this.localFormData.getAll('images') as File[];

    if ((images.length + files.length) > 6) {
      this.errorUpload.emit('Возможна загрузка не больше 6 фото или 1 видео');
      return;
    }

    // this.localFormData.delete('images');
    files.forEach((image: File) => this.localFormData.append('images', new Blob([image as Blob], { type: 'image/jpeg' })));
    this.localFormData.append('preview', new Blob([files[Number(this.localFormData.has('video'))] as Blob], { type: 'image/jpeg' }));
    files.forEach(image => this.generatePreview(image as File));
    this.formDataOut.emit(this.localFormData);
  }

  private onInputVideo(file: File): void {
    this.localFormData.append('video', file);
    this.formDataOut.emit(this.localFormData);
    const htmlVideoElement = document.createElement('video');
    htmlVideoElement.src = URL.createObjectURL(file);
    htmlVideoElement.muted = true;
    htmlVideoElement.play().then();
    htmlVideoElement.oncanplay = () => {
      htmlVideoElement.pause();
      const canvas = document.createElement('canvas');
      canvas.height = htmlVideoElement.videoHeight;
      canvas.width = htmlVideoElement.videoWidth;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(htmlVideoElement, 0, 0, canvas.width, canvas.height);
      this.preview.emit([canvas.toDataURL()]);
      this.localFormData.append('preview', new Blob([canvas.toDataURL()], { type: 'image/jpeg' }));
    };
  }

  private generatePreview(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.preview.emit([reader.result as string]);
    };
    reader.readAsDataURL(file);
  }

  @HostListener('input',  ['$event'])
  onInput($event: Event) {
    try {
      const files = ($event.target as HTMLInputElement).files as FileList;
      const images = Array.from(files)
        .filter(item => (item as File).type.includes('image'));
      const video = Array.from(files)
        .find(item => (item as File).type.includes('video'));


      if (images.length > 0) {
        this.onInputImages(images);
      }
      if (!!video) {
        this.onInputVideo(video);
      }
    } catch (e: unknown) {
      new Error(e as string);
    }
  }

}
