import { createAction, props } from '@ngrx/store';
import { ErrorResponse, User } from '@core/models';
import { BaseResponse, KYCStatus } from '@api/backend';

export enum EVerifyActions {
  // GetVerify = '[Verify] Get Verify',
  // GetVerifySuccess = '[Verify] Get Verify Success',
  // GetVerifyFailure = '[Verify] Get Verify Failure',
  GetVerifyStatus = '[Verify] Get Verify Status',
  GetVerifyStatusSuccess = '[Verify] Get Verify Status Success',
  GetVerifyStatusFailure = '[Verify] Get Verify Status Failure',
  PostVerifyDocument = '[Verify] Post Verify Document',
  PostVerifyDocumentSuccess = '[Verify] Post Verify Document Success',
  PostVerifyDocumentFailure = '[Verify] Post Verify Document Failure',
  PostVerifySelfie = '[Verify] Post Verify Selfie',
  PostVerifySelfieSuccess = '[Verify] Post Verify Selfie Success',
  PostVerifySelfieFailure = '[Verify] Post Verify Selfie Failure',
  PostVerifySelfieWithShortCode = '[Verify] Post Verify Selfie With Short Code',
  PostVerifySelfieWithShortCodeSuccess = '[Verify] Post Verify Selfie With Short Code Success',
  PostVerifySelfieWithShortCodeFailure = '[Verify] Post Verify Selfie With Short Code Failure',
  GetVerifyShortCode = '[Verify] Get Verify Short Code',
  GetVerifyShortCodeSuccess = '[Verify] Get Verify Short Code Success',
  GetVerifyShortCodeFailure = '[Verify] Get Verify Short Code Failure',
  WebhookVerify = '[Verify] Webhook Verify',
  WebhookVerifySuccess = '[Verify] Webhook Verify Success',
  WebhookVerifyFailure = '[Verify] Webhook Verify Failure',
  ClearKycLink = '[Verify] Clear Kyc Link',
}

export const getVerifyStatus = createAction(
  EVerifyActions.GetVerifyStatus,
);

export const getVerifyStatusSuccess = createAction(
  EVerifyActions.GetVerifyStatusSuccess,
  props<{ kycStatus: KYCStatus }>(),
);

export const getVerifyStatusFailure = createAction(
  EVerifyActions.GetVerifyStatusFailure,
  props<{ error: ErrorResponse }>(),
);


export const postVerifyDocument = createAction(
  EVerifyActions.PostVerifyDocument,
  props<{ file1: File, file2?: File }>(),
);

export const postVerifyDocumentSuccess = createAction(
  EVerifyActions.PostVerifyDocumentSuccess,
  props<{ data: BaseResponse }>(),
);

export const postVerifyDocumentFailure = createAction(
  EVerifyActions.PostVerifyDocumentFailure,
  props<{ error: ErrorResponse }>(),
);

export const postVerifySelfie = createAction(
  EVerifyActions.PostVerifySelfie,
  props<{ file: File }>(),
);

export const postVerifySelfieSuccess = createAction(
  EVerifyActions.PostVerifySelfieSuccess,
  props<{ data: BaseResponse }>(),
);

export const postVerifySelfieFailure = createAction(
  EVerifyActions.PostVerifySelfieFailure,
  props<{ error: ErrorResponse }>(),
);

export const postVerifySelfieWithShortCode = createAction(
  EVerifyActions.PostVerifySelfieWithShortCode,
  props<{ shortCode:string, file: File }>(),
);

export const postVerifySelfieWithShortCodeSuccess = createAction(
  EVerifyActions.PostVerifySelfieWithShortCodeSuccess,
  props<{ data: BaseResponse }>(),
);

export const postVerifySelfieWithShortCodeFailure = createAction(
  EVerifyActions.PostVerifySelfieWithShortCodeFailure,
  props<{ error: ErrorResponse }>(),
);

export const getVerifyShortCode = createAction(
  EVerifyActions.GetVerifyShortCode,
);

export const getVerifyShortCodeSuccess = createAction(
  EVerifyActions.GetVerifyShortCodeSuccess,
  props<{ shortCode: string }>(),
);

export const getVerifyShortCodeFailure = createAction(
  EVerifyActions.GetVerifyShortCodeFailure,
  props<{ error: ErrorResponse }>(),
);

export const webhookVerify = createAction(
  EVerifyActions.WebhookVerify,
  props<{ request_id: string }>(),
);
export const webhookVerifySuccess = createAction(
  EVerifyActions.WebhookVerifySuccess,
  props<{ user?: User }>(),
);
export const webhookVerifyFailure = createAction(
  EVerifyActions.WebhookVerifyFailure,
);
export const clearKycLink = createAction(
  EVerifyActions.ClearKycLink,
);


