import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, zip } from 'rxjs';
import { Interceptor } from '@core/services/interceptor/interceptor';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { sessionSelectors } from '@store/session/session.selectors';
import { GetSession } from '@core/models';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FingerprintjsProAngularService } from '@fingerprintjs/fingerprintjs-pro-angular';

@Injectable()
export class JwtInterceptor extends Interceptor implements HttpInterceptor {


  constructor(
    protected override store: Store,
    protected override actions: Actions,
    private jwtHelper: JwtHelperService,
    protected override fingerprintService: FingerprintjsProAngularService,
    @Inject(LOCALE_ID) protected override locale: string,
  ) {
    super(store, actions, fingerprintService, locale);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isDeleteMethod = request.method === 'DELETE';
    const notMatchSession = !request.url.match('auth/session');
    const notMatchAmazon = !request.url.match('amazon');
    const resolvedRequest = (notMatchAmazon && notMatchSession) || isDeleteMethod;

    const matchSessionToken = request.url.match('auth/session/token');

    if (!notMatchAmazon) {
      return next.handle(request.clone());
    } else {
      return zip(
        this.fingerprintService.getVisitorData(),
        this.store.select(sessionSelectors.selectSessionToken),
      ).pipe(
        switchMap(([visitor, tokenData]) => {
          const accessToken = tokenData.access_token;
          const refreshToken = tokenData.refresh_token;

          const getToken = (): string | undefined => {
            if (matchSessionToken && !!refreshToken) {
              return refreshToken;
            }
            if (resolvedRequest && !!accessToken) {
              return accessToken;
            }
            return undefined;
          };

          if (!getToken() && notMatchSession) {
            return this.getSession(GetSession.NEW, request, next);
          }

          if (getToken() && this.jwtHelper.isTokenExpired(accessToken) && !matchSessionToken) {
            return this.getSession(GetSession.REFRESH, request, next);
          }

          return next.handle(
            request.clone({
              setHeaders: this.setHeaders(visitor.visitorId, getToken(), visitor.requestId),
              withCredentials: false,
            }),
          );
        }),
      );
    }
  }

}
