import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { DIALOG_DATA } from '@app/services/dialog/dialog-tokens';
import { DialogRef } from '@app/services/dialog/dialog-ref.class';
import * as VerifyActions from '@store/verify/verify.actions';

export enum DialogMessageMode {
  POST_CREATE_INFO = 'post_create_info',
  POST_CREATE_VERIFY = 'post_create_verify',
  USER_FOLLOW_SUCCESS = 'user_follow_success',
}

@Component({
  selector: 'sens-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageDialogComponent {

  public modeType = DialogMessageMode;

  constructor(
    private store: Store,
    private ref: DialogRef,
    @Inject(DIALOG_DATA) public mode: DialogMessageMode,
  ) {}

  public onClose(): void {
    this.ref.closeImmediately();
  }

  public onVerify(): void {
    this.store.dispatch(VerifyActions.getVerifyStatus());
  }
}
