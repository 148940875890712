import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { I18NextModule } from 'angular-i18next';
import { CountdownModule } from 'ngx-countdown';
import { NgrxFormsModule } from 'ngrx-forms';

import { AuthComponent } from '@public/auth/auth.component';
import { AuthRoutingModule } from '@public/auth/auth-routing.module';
import { OtpComponent } from '@public/auth/components/otp/otp.component';
import { IconComponent } from '@shared/components';
import { ScryptService } from '@public/auth/services';
import { AuthFeatureModule } from '@store/auth';
import { ConfirmAgeComponent } from '@public/auth/confirm-age/confirm-age.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AuthComponent,
    OtpComponent,
    ConfirmAgeComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    NgrxFormsModule,
    CountdownModule,
    AuthRoutingModule,
    AuthFeatureModule,
    I18NextModule,
    NgOptimizedImage,
    IconComponent,
  ],
  exports: [
    AuthComponent,
    ConfirmAgeComponent,
  ],
  providers: [
    ScryptService,
  ],
})
export class AuthModule { }
