/**
 * Sens
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UpdateUser {
  user_name?: string;
  person_name?: string;
  trial_type?: UpdateUser.TrialTypeEnum;
  m1_price?: number;
  m3_price?: number;
  user_bio?: string;
  gender?: string;
}
export namespace UpdateUser {
  export type TrialTypeEnum =
    | 't0'
    | 't1'
    | 't2'
    | 't3'
    | 't4'
    | 't5'
    | 't6'
    | 't7';
  export const TrialTypeEnum = {
    T0: 't0' as TrialTypeEnum,
    T1: 't1' as TrialTypeEnum,
    T2: 't2' as TrialTypeEnum,
    T3: 't3' as TrialTypeEnum,
    T4: 't4' as TrialTypeEnum,
    T5: 't5' as TrialTypeEnum,
    T6: 't6' as TrialTypeEnum,
    T7: 't7' as TrialTypeEnum,
  };
}
