import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MergedRoute, MergedRouteReducerState } from '@store/router/merged-route';

const selectRouterFeature = createFeatureSelector<MergedRouteReducerState>('router');
const selectRouterState = createSelector(
  selectRouterFeature,
  (router: MergedRouteReducerState) => router.state,
);
const selectRouterNavigationId = createSelector(
  selectRouterFeature,
  (router: MergedRouteReducerState) => router.navigationId,
);
const selectRouterParams = createSelector(
  selectRouterState,
  (state: MergedRoute) => state.params,
);
const selectRouterParam = (param: string) => createSelector(
  selectRouterParams,
  (params) => params && params[param],
);

const selectQueryRouterParams = createSelector(
  selectRouterState,
  (state: MergedRoute) => state.queryParams,
);

const selectQueryRouterParam = (param: string) => createSelector(
  selectQueryRouterParams,
  (params) => params && params[param],
);
const selectFragment = createSelector(
  selectRouterState,
  (state: MergedRoute) => state.fragment,
);

export const routerSelectors = {
  selectRouterState,
  selectRouterNavigationId,
  selectRouterParams,
  selectRouterParam,
  selectQueryRouterParams,
  selectQueryRouterParam,
  selectFragment,
};
