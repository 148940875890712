/**
 * Sens
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Message } from './message';

export interface FullDialog {
  created_at?: any | null;
  dialog_id: string;
  avatar_url?: string;
  user_name?: string;
  person_name?: string;
  user_id?: string;
  is_following: boolean;
  last_message?: Message;
}
