import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { authFeatureKey, AuthFormState, AuthState } from '@store/auth';

const selectAuthFeature = createFeatureSelector<AuthState>(authFeatureKey);

const selectAuthState = createSelector(
  selectAuthFeature,
  (state) => state,
);

const selectAuthFormState = createSelector(
  selectAuthFeature,
  (state: AuthState) => state.form as FormGroupState<AuthFormState>,
);

export const authSelectors = {
  selectAuthFeature,
  selectAuthState,
  selectAuthFormState,
};

