import { createAction, props } from '@ngrx/store';
import { ActivatedRoute, Params } from '@angular/router';

export enum ERouterActions {
  Navigate = '[Router] User Navigate',
  NavigateSuccess = '[Router] User Navigate Success',
  GetBack = '[Router] Get Back',
  GetBackSuccess = '[Router] Get Back Success',
}

export const navigate = createAction(
  ERouterActions.Navigate,
  props<{ path: string, extras?: { queryParams?: Params, fragment?: string }, relativeTo?: ActivatedRoute }>(),
);
export const navigateSuccess = createAction(
  ERouterActions.NavigateSuccess,
);
export const getBack = createAction(
  ERouterActions.GetBack,
);
export const getBackSuccess = createAction(
  ERouterActions.GetBackSuccess,
);

export const routerActions = {
  getBack,
  navigate,
};
