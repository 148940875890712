import { Observable } from 'rxjs';
import { Response, TransactionList } from '@core/models';
import { CurrenciesList, InvoiceLink, PaymentTypeList } from '@api/backend';

export abstract class PaymentRepository {

  public abstract getCurrencyList(): Observable<CurrenciesList>;

  public abstract getPaymentTypesList(): Observable<PaymentTypeList>;

  /**
   * @method GET
   * @return Observable<InvoiceLinkResponse>
   */
  // public abstract getPaymentCharge(amount: number): Observable<InvoiceLinkResponse>;

  /**
   * @method GET
   * @return Observable<InvoiceLinkResponse>
   */
  public abstract getPaymentTransactions(page?: number): Observable<TransactionList>;

  public abstract postPaymentCharge(paymentType: string, amount: number): Observable<InvoiceLink>;

  /**
   * @method POST
   * @return Observable<Response>
   */
  public abstract postPaymentWithdraw(paymentType: string, amount: number, address: string, currency: string,): Observable<Response>;

  public abstract checkCard(value: string): Observable<Response>;

  public abstract checkCryptoWallet(value: string): Observable<Response>;

}
