import { createAction, props } from '@ngrx/store';
import { ErrorResponse, Media, MediaListResponse } from '@core/models';
import { TypedAction } from '@ngrx/store/src/models';
import { ViewData } from '@api/backend';

export type LoadUserPostsActionsType = { newUpload?: boolean } & TypedAction<EPostActions.loadCurrentUserPosts | EPostActions.loadBrowsingUserPosts>;

export enum EPostActions {

  loadFeedInit = '[Post] Load Feed Init',
  loadFeed = '[Post] Load Feed',
  loadFeedSuccess = '[Post] Load Feed Success',
  loadFeedFailure = '[Post] Load Feed Failure',

  clearFeed = '[Post] Clear Feed',

  loadCurrentUserPosts = '[Post] Load Current User Posts',
  loadBrowsingUserPosts = '[Post] Load Browsing User Posts',
  loadUserPosts = '[Post] Load User Posts',
  loadUserPostsSuccess = '[Post] Load User Posts Success',
  loadUserPostsFailure = '[Post] Load User Posts Failure',

  loadPost = '[Post] Load Post',
  loadPostSuccess = '[Post] Load Post Success',
  loadPostFailure = '[Post] Load Post Failure',

  likePost = '[Post] Like Post',
  likePostSuccess = '[Post] Like Post Success',
  likePostFailure = '[Post] Like Post Failure',

  unlikePost = '[Post] Unlike Post',
  unlikePostSuccess = '[Post] Unlike Post Success',
  unlikePostFailure = '[Post] Unlike Post Failure',

  shareAuthor = '[Post] Share Author',
  shareAuthorSuccess = '[Post] Share Author Success',
  shareAuthorFailure = '[Post] Share Author Failure',

  sharePost = '[Post] Share Post',
  sharePostSuccess = '[Post] Share Post Success',
  sharePostFailure = '[Post] Share Post Failure',

  deletePost = '[Post] Delete Post',
  deletePostSuccess = '[Post] Delete Post Success',
  deletePostFailure = '[Post] Delete Post Failure',

  clearPosts = '[Post] Clear Posts',

  scrollFeed = '[Post] Scroll Feed',

  hidePost = '[Post] Hide Post',
  hidePostSuccess = '[Post] Hide Post Success',
  hidePostFailure = '[Post] Hide Post Failure',

  complaintPost = '[Post] Complaint Post',
  complaintPostSuccess = '[Post] Complaint Post Success',
  complaintPostFailure = '[Post] Complaint Post Failure',

  viewPost = '[Post] View Post',
  viewPostSuccess = '[Post] View Post Success',
  viewPostFailure = '[Post] View Post Failure',
}

/**
 * Feed
 */

export const loadFeedInit = createAction(
  EPostActions.loadFeedInit,
  props<{ newUpload?: boolean }>(),
);

export const loadFeed = createAction(
  EPostActions.loadFeed,
  props<{ newUpload?: boolean, nextPage?: number }>(),
);

export const loadFeedSuccess = createAction(
  EPostActions.loadFeedSuccess,
  props<{ postsPage: MediaListResponse, newUpload?: boolean }>(),
);

export const loadFeedFailure = createAction(
  EPostActions.loadFeedFailure,
  props<{ error: ErrorResponse }>(),
);

export const clearFeed = createAction(
  EPostActions.clearFeed,
);

/**
 * User posts
 */

export const loadCurrentUserPosts = createAction(
  EPostActions.loadCurrentUserPosts,
  props<{ newUpload?: boolean }>(),
);

export const loadBrowsingUserPosts = createAction(
  EPostActions.loadBrowsingUserPosts,
  props<{ newUpload?: boolean }>(),
);

export const loadUserPosts = createAction(
  EPostActions.loadUserPosts,
  props<{ userId: string, newUpload: boolean, nextPage?: number | undefined }>(),
);

export const loadUserPostsSuccess = createAction(
  EPostActions.loadUserPostsSuccess,
  props<{ postsPage: MediaListResponse, userId: string, newUpload?: boolean }>(),
);

export const loadUserPostsFailure = createAction(
  EPostActions.loadUserPostsFailure,
  props<{ error: ErrorResponse }>(),
);


/**
 * Post
 */

export const loadPost = createAction(
  EPostActions.loadPost,
);

export const loadPostSuccess = createAction(
  EPostActions.loadPostSuccess,
  props<{ post: Media }>(),
);

export const loadPostFailure = createAction(
  EPostActions.loadPostFailure,
  props<{ error: ErrorResponse }>(),
);

export const likePost = createAction(
  EPostActions.likePost,
  props<{ post_id: string }>(),
);

export const likePostSuccess = createAction(
  EPostActions.likePostSuccess,
  props<{ post_id: string }>(),
);

export const likePostFailure = createAction(
  EPostActions.likePostFailure,
  props<{ error: ErrorResponse }>(),
);

export const unlikePost = createAction(
  EPostActions.unlikePost,
  props<{ post_id: string }>(),
);

export const unlikePostSuccess = createAction(
  EPostActions.unlikePostSuccess,
  props<{ post_id: string }>(),
);

export const unlikePostFailure = createAction(
  EPostActions.unlikePostFailure,
  props<{ error: ErrorResponse }>(),
);

export const shareAuthor = createAction(
  EPostActions.shareAuthor,
  props<{ userId: string }>(),
);

export const shareAuthorSuccess = createAction(
  EPostActions.shareAuthorSuccess,
);

export const shareAuthorFailure = createAction(
  EPostActions.shareAuthorFailure,
  props<{ error: ErrorResponse }>(),
);

export const sharePost = createAction(
  EPostActions.sharePost,
  props<{ userId: string, post_id: string }>(),
);

export const sharePostSuccess = createAction(
  EPostActions.sharePostSuccess,
);

export const sharePostFailure = createAction(
  EPostActions.sharePostFailure,
  props<{ error: ErrorResponse }>(),
);

export const deletePost = createAction(
  EPostActions.deletePost,
  props<{ post_id: string }>(),
);

export const deletePostSuccess = createAction(
  EPostActions.deletePostSuccess,
  props<{ post_id: string }>(),
);

export const deletePostFailure = createAction(
  EPostActions.deletePostFailure,
  props<{ error: ErrorResponse }>(),
);

export const clearPosts = createAction(
  EPostActions.clearPosts,
);

export const scrollFeed = createAction(
  EPostActions.scrollFeed,
  props<{ scrollOffset: number }>(),
);


export const hidePost = createAction(
  EPostActions.hidePost,
  props<{ post_id: string }>(),
);

export const hidePostSuccess = createAction(
  EPostActions.hidePostSuccess,
);

export const hidePostFailure = createAction(
  EPostActions.hidePostFailure,
  props<{ error: ErrorResponse }>(),
);

export const complaintPost = createAction(
  EPostActions.complaintPost,
  props<{ post_id: string, complaint_id: string, comment?: string }>(),
);

export const complaintPostSuccess = createAction(
  EPostActions.complaintPostSuccess,
);

export const complaintPostFailure = createAction(
  EPostActions.complaintPostFailure,
  props<{ error: ErrorResponse }>(),
);


const viewPost = createAction(
  EPostActions.viewPost,
  props<{ mediaId: string, viewData: ViewData }>(),
);

const viewPostSuccess = createAction(
  EPostActions.viewPostSuccess,
);

const viewPostFailure = createAction(
  EPostActions.viewPostFailure,
  props<{ error: ErrorResponse }>(),
);

export const postActions = {
  viewPost,
  viewPostSuccess,
  viewPostFailure,
};

