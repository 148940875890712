import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { sessionSelectors } from '@store/session/session.selectors';
import { Store } from '@ngrx/store';
import { CookieService } from '@core/services/cookies/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class VerifyGuard {

  constructor(
    private router: Router,
    private store: Store,
    private cookieService: CookieService,
  ) {}

  canLoad(): Observable<boolean> {
    const code =  this.router.getCurrentNavigation()?.initialUrl?.queryParams['short_code'] || !!this.cookieService.get('short_code') ;
    if (code) {
      this.cookieService.set('short_code', code);
      return of(true);
    }

    return this.store.select(sessionSelectors.selectIsSetUser).pipe(
      map(isLoggedIn => {
        if (!isLoggedIn) {
          this.router.navigate(['/']).then();
          return false;
        }
        return true;
      }),
    );
  }

}
