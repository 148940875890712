import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { from, Observable } from 'rxjs';
import scrypt from 'scrypt-js';
import { ScryptChallenge } from '@core/models';

@Injectable()
export class ScryptService {
  public scrypt(challenge: ScryptChallenge): Observable<string> {
    const password = new Buffer(challenge.input);
    const salt = new Buffer(challenge.salt);
    return from(scrypt.scrypt(password, salt, challenge.n, challenge.r, challenge.p, challenge.dklen)
      .then((uint8Arr: Uint8Array) => Buffer.from(uint8Arr, uint8Arr.byteOffset, uint8Arr.byteLength).toString('hex')));
  }
}
