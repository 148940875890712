import { Injectable } from '@angular/core';
import { FapRepository } from '@core/repositories';
import { map, Observable } from 'rxjs';
import { BaseFapResponse, BaseResponse, FapResponse, FapService, ViewData } from '@api/backend';
import { Fap, FapList, Response } from '@core/models';

@Injectable()
export class FapWebRepository extends FapRepository {

  constructor(
    private fapService: FapService,
  ) {
    super();
  }

  public loadFeed(p?: number): Observable<FapList> {
    try {
      return this.fapService.fapRecommendationFapRecommendationGet(p)
        .pipe(map(({ data }) => data));
    } catch {
      throw new Error('Can\'t execute loadFeed api2 method');
    }
  }

  public loadFap(fapId: string): Observable<Fap> {
    try {
      return this.fapService.getFapFapFapIdGet(fapId)
        .pipe(map(({ data }) => data));
    } catch {
      throw new Error('Can\'t execute loadFap api2 method');
    }
  }

  public likeMedia(fap_id: string): Observable<BaseResponse> {
    return this.fapService.postFapLikeFapFapIdLikePost(fap_id);
  }

  public unlikeMedia(fap_id: string): Observable<BaseResponse> {
    return this.fapService.postFapUnlikeFapFapIdUnlikePost(fap_id);
  }

  public shareMedia(fap_id: string): Observable<BaseResponse> {
    return this.fapService.postFapShareFapFapIdSharePost(fap_id);
  }

  public complaintMedia(media_id: string, complaintTypeId: string, comment?: string): Observable<BaseResponse> {
    return this.fapService.postFapComplaintFapFapIdComplaintPost(media_id, {
      complaint_type_id: complaintTypeId,
      comment,
    });
  }

  public view(fapId: string, data: ViewData): Observable<BaseResponse> {
    return this.fapService.postFapViewFapFapIdViewPost(fapId, data);
  }

  public upload(fap_name?: string, fap_description?: string, preview?: Blob): Observable<BaseFapResponse> {
    return this.fapService.createFapFapPost(fap_name, fap_description, preview);
  }

  public delete(fapId: string): Observable<Response> {
    return this.fapService.deleteFapFapFapIdDelete(fapId);
  }
}
