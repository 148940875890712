import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[sensAvatar]',
})
export class AvatarDirective {

  constructor(
    private elementRef: ElementRef,
  ) { }


  @HostListener('error', ['$event'])
  onError(): void {
    this.elementRef.nativeElement.remove();
  }

}
