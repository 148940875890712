import { Directive, EmbeddedViewRef, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngFor][ngForOf][ngForEmpty]',
})
export class ForDirective implements OnChanges {

  @Input() ngForOf: unknown[] | null = [];

  @Input() ngForEmpty?: TemplateRef<Record<string, unknown>>;

  private ref?: EmbeddedViewRef<Record<string, unknown>>;

  constructor(private readonly vcr: ViewContainerRef) {}

  ngOnChanges() {
    this.ref?.destroy();

    if (this.ngForEmpty && (this.ngForOf || []).length === 0) {
      this.ref = this.vcr.createEmbeddedView(this.ngForEmpty);
    }
  }
}
