// noinspection SpellCheckingInspection
export const VERSION = '0.0.0';
export const TOKEN_NAME = 'SensWebFront';
export const FIREBASE_APP_NAME = 'SensFire';
export const YMETRIKA_CONFIG = {
  id: 88234843,
  clickmap:true,
  trackLinks:true,
  accurateTrackBounce:true,
  webvisor:false,
};
