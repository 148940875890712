import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authFeatureKey, authReducer, AuthEffects } from '@store/auth';
import { AuthRepository } from '@core/repositories';
import { AuthWebRepository } from '@data/repositories';


@NgModule({
  imports: [
    StoreModule.forFeature(authFeatureKey, authReducer),
    EffectsModule.forFeature([ AuthEffects ]),
  ],
  exports: [
    StoreModule,
  ],
  providers: [
    { provide: AuthRepository, useClass: AuthWebRepository },
  ],
})
export class AuthFeatureModule { }
