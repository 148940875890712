import { AfterViewInit, DestroyRef, Directive, EventEmitter, inject, Input, Output } from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { debounceTime, of, Subject, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[sensInfiniteScroll]',
})
export class InfiniteScrollDirective implements AfterViewInit {

  private destroyRef = inject(DestroyRef);

  private scrollEventDebouncer = new Subject<number>();

  private isFired = false;

  /**
   * proportion (< 1 ) or fire distance in pixels ('200px')
   */
  @Input() targetToFire: number | string = 0.1;

  /**
   * to import a scrollable element if it does not belong to the global scrollable
   */
  @Input() cdkScrollable?: CdkScrollable;

  @Input() debounceTime = 500;

  @Output() scrolled = new EventEmitter<void>();

  @Output() scrollEvent = new EventEmitter<number>();

  constructor(
    private sd: ScrollDispatcher,
  ) {
    this.scrollEventDebouncer
      .pipe(
        debounceTime(this.debounceTime),
        takeUntilDestroyed(),
      ).subscribe(
        value => this.scrollEvent.emit(value),
      );
  }

  public ngAfterViewInit() {
    this.eventSource
      .pipe(
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(
        (cs: CdkScrollable | void) => this.handleScrollEvent(cs),
      );
  }

  private get eventSource() {
    return this.cdkScrollable
      ? this.cdkScrollable.elementScrolled().pipe(switchMap(() => of(this.cdkScrollable)))
      : this.sd.scrolled();
  }

  private handleScrollEvent(cs?: CdkScrollable | void) {
    if (!cs) {
      return;
    }
    this.scrollEventDebouncer.next(cs.measureScrollOffset('top'));
    const scrolledUntilNow = cs.measureScrollOffset('bottom');

    if (typeof this.targetToFire === 'string') {
      const targetToFire = Number.parseInt(this.targetToFire);
      if (Number.isNaN(targetToFire)) {
        return;
      }
      this.fireIf(scrolledUntilNow <= targetToFire);
    } else {
      const contentHeight = cs.getElementRef().nativeElement.scrollHeight;
      const scrolledPercent = scrolledUntilNow / contentHeight;
      this.fireIf(scrolledPercent <= this.targetToFire);
    }
  }

  private fireIf(condition: boolean): void {
    if (condition) {
      if (!this.isFired) {
        this.isFired = true;
        this.scrolled.emit();
      }
    } else {
      this.isFired = false;
    }
  }

}
