import { Observable } from 'rxjs';
import { MediaListResponse, MediaRequest, MediaResponse, PresignedRequest, Response } from '@core/models';
import { ViewData } from '@api/backend';


export abstract class MediaRepository {

  /**
   * @method POST
   * @param body
   * @return Observable<MediaResponse>
   */
  public abstract postMediaVideo(body: MediaRequest): Observable<MediaResponse>;

  /**
   * @method POST
   * @param body
   * @return Observable<MediaResponse>
   */
  public abstract postMediaPhoto(body: MediaRequest): Observable<MediaResponse>;

  /**
   * @method POST
   * @param body
   * @return Observable<Response>
   */
  public abstract uploadVideo(body: PresignedRequest, file: Blob): Observable<Response>;

  /**
   * @method GET
   * @param page
   * @return Observable<MediaListResponse>
   */
  public abstract getMediaRecommendationFeed(page?: number): Observable<MediaListResponse>;

  /**
   * @method GET
   * @param page
   * @return Observable<MediaListResponse>
   */
  public abstract getMediaFollowingFeed(page?: number): Observable<MediaListResponse>;

  /**
   * @method GET
   * @param media_id
   * @return Observable<MediaResponse>
   */
  public abstract getMediaById(media_id: string): Observable<MediaResponse>;

  /**
   * @method DELETE
   * @param media_id
   * @return Observable<Response>
   */
  public abstract deleteMediaById(media_id: string): Observable<Response>;

  /**
   * @method Post
   * @param media_id
   * @return Observable<Response>
   */
  public abstract postMediaLike(media_id: string): Observable<Response>;

  /**
   * @method Post
   * @param media_id
   * @return Observable<Response>
   */
  public abstract postMediaUnlike(media_id: string): Observable<Response>;


  /**
   * @method Post
   * @param media_id
   * @return Observable<Response>
   */
  public abstract postAuthorShare(userId: string): Observable<Response>;

  /**
   * @method Post
   * @param media_id
   * @return Observable<Response>
   */
  public abstract postMediaShare(media_id: string): Observable<Response>;

  /**
   * @method Post
   * @param media_id
   * @return Observable<Response>
   */
  public abstract postMediaHide(media_id: string): Observable<Response>;

  /**
   * @method Post
   * @param media_id
   * @param complaintTypeId
   * @param comment
   * @return Observable<Response>
   */
  public abstract postMediaComplaint(media_id: string, complaintTypeId: string, comment?: string): Observable<Response>;

  /**
   * @method Post
   * @return Observable<Response>
   * @param mediaId
   * @param view
   */
  public abstract postMediaView(mediaId: string, view: ViewData): Observable<Response>;

}
