import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import * as RouterActions from '@store/router/router.actions';
import { debounceTime, distinct, from, map, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { routerSelectors } from '@store/router/router.selectors';
import { Location } from '@angular/common';


@Injectable()
export class RouterEffects {

  constructor(
    private store: Store,
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {
  }

  public navigate = createEffect(
    () => {
      return this.actions.pipe(
        ofType(RouterActions.navigate),
        distinct(),
        switchMap((action) => from(this.router.navigate(
          [ action.path ],
          {
            queryParams: action.extras?.queryParams,
            fragment: action.extras?.fragment || undefined,
            relativeTo: action.relativeTo ? this.route : undefined,
            preserveFragment: false,
          },
        ))),
        map(() => RouterActions.navigateSuccess()),
      );
    },
  );

  public getBack = createEffect(
    () => {
      return this.actions.pipe(
        ofType(RouterActions.getBack),
        debounceTime(100),
        concatLatestFrom(() => this.store.select(routerSelectors.selectRouterNavigationId)),
        switchMap(([ , navigationId ]) => {
          if (navigationId > 0) {
            this.location.back();
            return of(true);
          } else {
            return from(this.router.navigateByUrl('/'));
          }
        }),
        map(() => RouterActions.getBackSuccess()),
      );
    },
  );

  // public removeErrorOnNavigate = createEffect(
  //   () => {
  //     return this.actions.pipe(
  //       ofType(ROUTER_REQUEST),
  //       concatLatestFrom(() => this.store.select(selectGlobalError)),
  //       switchMap(([ , error ]) => error ? of(ErrorActions.removeError()) : EMPTY),
  //     );
  //   },
  // );
}
