import { Injectable } from '@angular/core';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { COOKIE } from '@data/const';
import { map, Observable, ReplaySubject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class CookieService {

  private tokenSubject = new ReplaySubject<Record<string, string>>(1);


  constructor(
    private ngxCookieService: NgxCookieService,
  ) {
    this.tokenSubject.next(this.ngxCookieService.getAll());
  }

  public get(tokenName: string): string {
    return this.ngxCookieService.get(tokenName);
  }

  public getAsync(tokenName: string): Observable<string> {
    return this.tokenSubject
      .asObservable()
      .pipe(
        map(cookieStore => cookieStore[tokenName] ?? ''),
      );
  }

  public set(tokenName: string, token: string, expires: number | Date = <number>COOKIE.EXPIRE, path = <string>COOKIE.PATH): void {
    this.ngxCookieService.set(
      tokenName,
      token,
      expires,
      path,
    );
    this.tokenSubject.next(this.ngxCookieService.getAll());
  }

  public check(name: string): boolean {
    return this.ngxCookieService.check(name);
  }

  public clear(name?: string): void {
    if (name) {
      this.ngxCookieService.delete(name, <string>COOKIE.PATH);
      return;
    }
    this.ngxCookieService.deleteAll(<string>COOKIE.PATH);
  }

}
