import { ActionReducer, createAction, MetaReducer, StoreModule } from '@ngrx/store';
import { environment } from '@environments/environment';
import { NgrxRouterStoreModule } from '@store/router';
import { SessionFeatureModule } from '@store/session';
import { LayoutFeatureModule } from '@store/layout';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UserFeatureModule } from '@store/user';

export interface State { }

export function debug(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state, action) {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.log('state', state);
      // eslint-disable-next-line no-console
      console.log('action', action);
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [
  // debug,
];

export const AppStateFeatureModules = [
  SessionFeatureModule,
  UserFeatureModule,
  NgrxRouterStoreModule,
  LayoutFeatureModule,
  StoreModule.forRoot({}, { metaReducers }),
  EffectsModule.forRoot(),
  StoreDevtoolsModule.instrument({ maxAge: 30, logOnly: environment.production }),
];

export const GlobalActions = {
  initPrivateModule: createAction(
    '[Global] Init Private Module',
  ),
};
