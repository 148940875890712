import { TransactionResponse } from '@api/backend';

export type Transaction = TransactionResponse;

export type TransactionList = {
  transactions: Array<Transaction>;
  next_page?: number;
};

export type TransactionListResponse = {
  data: TransactionList
};

export type Payment = {
  card_number: string
};
export type PaymentList = {
  payments: Array<Payment>;
  next_page?: number;
};
export type PaymentListResponse = {
  data: PaymentList
};

