import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[sensOnlyDigitsInputMask]',
})
export class OnlyDigitsInputMaskDirective {

  @HostListener('input',  ['$event'])
  onInput(event: Event) {
    const target = event.target as HTMLInputElement;
    target.value = target.value.replace(/\D+/gi, '');
  }
}
