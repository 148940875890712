export * from './auth.service';
import { AuthService } from './auth.service';
export * from './common.service';
import { CommonService } from './common.service';
export * from './fap.service';
import { FapService } from './fap.service';
export * from './media.service';
import { MediaService } from './media.service';
export * from './message.service';
import { MessageService } from './message.service';
export * from './payment.service';
import { PaymentService } from './payment.service';
export * from './stream.service';
import { StreamService } from './stream.service';
export * from './system.service';
import { SystemService } from './system.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [
  AuthService,
  CommonService,
  FapService,
  MediaService,
  MessageService,
  PaymentService,
  StreamService,
  SystemService,
  UserService,
];
