import { createAction, props } from '@ngrx/store';

import { AuthStep, ErrorResponse, Session, ScryptChallenge } from '@core/models';

export const authActions = {
  /**
   * Clear
   */
  clearState: createAction('[Auth] Clear State'),
  clearAuthErrors: createAction('[Auth] Clear Auth Errors'),
  /**
   * Scrypt Challenge
   */
  getScryptChallenge: createAction('[Auth] Get Scrypt Challenge'),
  getScryptChallengeSuccess: createAction(
    '[Auth] Get Scrypt Challenge Success',
    props<{ challenge: ScryptChallenge }>(),
  ),
  getScryptChallengeFailure: createAction('[Auth] Get Scrypt Challenge Failure', props<{ error: ErrorResponse }>()),
  /**
   * Scrypt Hash
   */
  getScryptHash: createAction('[Auth] Get Scrypt Hash'),
  getScryptHashSuccess: createAction('[Auth] Get Scrypt Hash Success', props<{ hash: string }>()),
  getScryptHashFailure: createAction('[Auth] Get Scrypt Hash Failure', props<{ error: ErrorResponse }>()),
  /**
   * Get Code
   */
  getCode: createAction('[Auth] Get Code'),
  getCodeSuccess: createAction('[Auth] Get Code Success'),
  getCodeFailure: createAction('[Auth] Get Code Failure', props<{ error: ErrorResponse }>()),
  /**
   * Verify Code
   */
  verifyCode: createAction('[Auth] Verify Code'),
  verifyCodeSuccess: createAction('[Auth] Verify Code Success', props<{ token: Session; step?: AuthStep }>()),
  verifyCodeFailure: createAction('[Auth] Verify Code Failure', props<{ error: ErrorResponse }>()),
  /**
   * Sign Eula
   */
  signEula: createAction('[Auth] Sign Eula'),
  signEulaSuccess: createAction('[Auth] Sign Eula Success', props<{ token: Session }>()),
  signEulaFailure: createAction('[Auth] Sign Eula Failure', props<{ error: ErrorResponse }>()),
};
