// import { DependencyInjectionModule } from '@shared/dependency-injection/dependency-injection.module';
// import { PRIME_NG_PROVIDERS } from '@shared/prime-ng/prime-ng.providers';
// import { SplashComponent } from '@shared/components/splash/splash.component';
// import { WEB_REPOSITORY_PROVIDERS } from '@data/repositories';
import { NgIf, NgOptimizedImage, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { Router } from '@angular/router';

import { JwtModule } from '@auth0/angular-jwt';
import { FingerprintJSPro, FingerprintjsProAngularModule } from '@fingerprintjs/fingerprintjs-pro-angular';
import { I18NextModule } from 'angular-i18next';
import * as Sentry from '@sentry/angular-ivy';

import { ApiModule as BackendApiModule, Configuration } from '@api/backend';
import { CoreModule } from '@core/core.module';
import { getCookie } from '@core/helpers/cookie.helper';
import { I18N_PROVIDERS } from '@core/i18n/i18n';
import { BREAKPOINTS_INITIALIZER } from '@core/services/breakpoints';
import { ErrorInterceptor } from '@core/services/interceptor/error.interceptor';
import { JwtInterceptor } from '@core/services/interceptor/jwt.interceptor';
import { TOKEN_NAME } from '@data/config';
import { environment } from '@environments/environment';
import { AppStateFeatureModules } from '@store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { OverlayModule } from '@angular/cdk/overlay';
import { ROUTE_REUSE_STRATEGY_PROVIDER } from '@shared/route-reuse-strategy/app-route-reuse-strategy';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { StreamToastService } from '@app/services/stream-toast/stream-toast.service';
import { AuthModule } from '@public/auth/auth.module';
import { IconComponent } from '@shared/components';
import { NgrxFormsModule } from 'ngrx-forms';
import { register } from 'swiper/element/bundle';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ServiceWorkerModule } from '@angular/service-worker';

register();

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CoreModule,
    OverlayModule,
    AuthModule,
    AppStateFeatureModules,
    I18NextModule.forRoot(),
    JwtModule.forRoot({ config: { tokenGetter: () => getCookie(TOKEN_NAME) } }),
    FingerprintjsProAngularModule.forRoot({
      loadOptions: {
        apiKey: environment.fingerprintApiKey,
        endpoint: [
          'https://sens.love/u1ydfpG2ZENzP4IJ/KFXeNOUSluDDDUVf',
          FingerprintJSPro.defaultEndpoint,
        ],
        scriptUrlPattern: [
          'https://sens.love/u1ydfpG2ZENzP4IJ/lRE1TJUtYEXJE9oM?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>',
          FingerprintJSPro.defaultScriptUrlPattern,
        ],
      },
    }),
    BackendApiModule.forRoot(() => new Configuration({ basePath: environment.base_url })),
    ToastrModule.forRoot(),
    IconComponent,
    NgIf,
    NgOptimizedImage,
    NgrxFormsModule,
    DirectivesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],

  providers: [
    I18N_PROVIDERS,
    BREAKPOINTS_INITIALIZER,
    ROUTE_REUSE_STRATEGY_PROVIDER,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({
      showDialog: false,
    }) },
    { provide: Sentry.TraceService, deps: [ Router ] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    MessageService, StreamToastService,
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule {}
