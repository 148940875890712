import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[sensSetWithdrawAddressValue]',
})
export class SetWithdrawAddressValueDirective {

  private debounceSubject = new Subject<string>();

  @Input() formatCreditCard = true;

  @Input() creditCardDigits: 16 | 18 = 16;

  @Input() updateOnType: 'changed' | 'blur' = 'changed';

  @Output() inputChanged = new EventEmitter<string>();

  @Input() set sensSetWithdrawAddressValue(value: string | null) {
    this.input.value = this.updateInputValue(value ?? '');
  }

  private get input(): HTMLInputElement {
    return this.elementRef.nativeElement as HTMLInputElement;
  }

  constructor(
    private elementRef: ElementRef,
  ) {
    this.debounceSubject.asObservable()
      .pipe(
        debounceTime(1000),
        takeUntilDestroyed(),
      ).subscribe(value => {
        if (this.updateOnType === 'changed') {
          this.inputChanged.emit(value);
        }
      });
  }

  @HostListener('input') onInput() {
    this.input.value = this.updateInputValue(this.input.value);
    if (this.updateOnType === 'changed') {
      this.debounceSubject.next(this.getCleanValue());
    }
  }

  @HostListener('blur') sendEvent() {
    if (this.updateOnType === 'blur') {
      this.inputChanged.emit(this.getCleanValue());
    }
  }

  private updateInputValue(value: string): string {
    return this.formatCreditCard
      ? this.ccFormat(value)
      : value.trim();
  }

  private getCleanValue(): string {
    return this.formatCreditCard
      ? this.clearValue(this.input.value)
      : this.input.value;
  }

  private ccFormat(value: string): string {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    let res = '';
    for (let i = 0; i < v.length; i++) {
      res += v[i];
      if ((i + 1) % 4 === 0) {
        res += ' ';
      }
    }
    return res.trim().substring(0, this.creditCardDigits === 16 ? 19 : 22);
  }

  private clearValue(value: string): string {
    return value.replace(/\s+/g, '');
  }

}
