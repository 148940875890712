import { layoutFeatureKey, LayoutState } from '@store/layout';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SensBreakpoint } from '@data/enums';

const selectLayoutState = createFeatureSelector<LayoutState>(layoutFeatureKey);
const selectBreakpoint = createSelector(selectLayoutState, (state) => state.breakpoint);

const selectIsMobile = createSelector(selectBreakpoint,
  (bp) => bp === SensBreakpoint.MOBILE || bp === SensBreakpoint.TABLET);

const selectIsDesktop = createSelector(selectBreakpoint,
  (bp) => bp === SensBreakpoint.DESKTOP);


export const layoutSelectors = {
  selectLayoutState,
  selectBreakpoint,
  selectIsMobile,
  selectIsDesktop,
};
