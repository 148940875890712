import { Observable } from 'rxjs';
import { Fap, FapList, Response } from '@core/models';
import { BaseFapResponse, BaseResponse, ViewData } from '@api/backend';

export abstract class FapRepository {

  public abstract loadFap(fapId: string): Observable<Fap>;
  public abstract loadFeed(p?: number): Observable<FapList>;
  public abstract likeMedia(fap_id: string): Observable<BaseResponse>;
  public abstract unlikeMedia(fap_id: string): Observable<BaseResponse>;
  public abstract shareMedia(fap_id: string): Observable<BaseResponse>;
  public abstract complaintMedia(media_id: string, complaintTypeId: string, comment?: string): Observable<BaseResponse>;
  public abstract view(fapId: string, data: ViewData): Observable<BaseResponse>;
  public abstract upload(fap_name?: string, fap_description?: string, preview?: Blob): Observable<BaseFapResponse>;
  public abstract delete(fapId?: string): Observable<Response>;

}
