import { createAction, props } from '@ngrx/store';
import { ErrorResponse, Session } from '@core/models';


export const sessionActions = {
  /**
   * Logout
   */
  logout: createAction(
    '[Session] Logout',
  ),
  /**
   * Get Auth Session
   */
  getAuthSession: createAction(
    '[Session] Get Auth Session',
  ),
  getAuthSessionSuccess: createAction(
    '[Session] Get Auth Session Success',
    props<{ token: Session }>(),
  ),
  getAuthSessionFailure: createAction(
    '[Session] Get Auth Session Failure',
    props<{ error: ErrorResponse }>(),
  ),
  /**
   * Refresh Auth Session
   */
  refreshAuthSession: createAction(
    '[Session] Refresh Auth Session',
  ),
  refreshAuthSessionSuccess: createAction(
    '[Session] Refresh Auth Session Success',
    props<{ token: Session }>(),
  ),
  refreshAuthSessionFailure: createAction(
    '[Session] Refresh Auth Session Failure',
    props<{ error: ErrorResponse }>(),
  ),
  /**
   * Get Auth Token
   */
  getAuthTokenSuccess: createAction(
    '[Session] Get Auth Token Success',
    props<{ token: Session }>(),
  ),
  /**
   * Remove Auth Session
   */
  removeAuthSession: createAction(
    '[Session] Remove Auth Session',
  ),
  removeAuthSessionSuccess: createAction(
    '[Session] Remove Auth Session Success',
  ),
  removeAuthSessionFailure: createAction(
    '[Session] Remove Auth Session Failure',
    props<{ error: ErrorResponse }>(),
  ),
};
