import { map, Observable } from 'rxjs';
import { Response, TransactionList } from '@core/models';
import { PaymentRepository } from '@core/repositories/payment.repository';
import { Injectable } from '@angular/core';
import { CurrenciesList, InvoiceLink, PaymentService, PaymentTypeList } from '@api/backend';

@Injectable()
export class PaymentWebRepository extends PaymentRepository {
  constructor(
    private paymentService: PaymentService,
  ) {
    super();
  }

  public getCurrencyList(): Observable<CurrenciesList> {
    try {
      return this.paymentService.getCurrenciesPaymentCurrenciesGet()
        .pipe(
          map(({ data }) => data),
        );
    } catch {
      throw new Error('Can\'t execute getCurrencyList api2 method');
    }
  }

  public getPaymentTypesList(): Observable<PaymentTypeList> {
    try {
      return this.paymentService.getPaymentTypesPaymentTypesGet()
        .pipe(
          map(({ data }) => data ),
        );
    } catch {
      throw new Error('Can\'t execute getCurrencyList api2 method');
    }
  }

  public getPaymentTransactions(page?: number): Observable<TransactionList> {
    try {
      return this.paymentService.getTransactionsPaymentTransactionsGet(page)
        .pipe(
          map(({ data }) => data ),
        );
    } catch (e) {
      throw new Error('Can\'t execute getPaymentTransactions api2 method');
    }
  }

  public postPaymentCharge(paymentType: string, amount: number): Observable<InvoiceLink> {
    try {
      return this.paymentService.chargeBalancePaymentPaymentTypeChargeGet(paymentType, amount)
        .pipe(
          map(({ data }) => data),
        );
    } catch (e) {
      throw new Error('Can\'t execute postPaymentWithdraw api2 method');
    }
  }

  public postPaymentWithdraw(paymentType: string, amount: number, address: string, currencyIso: string): Observable<Response> {
    try {
      return this.paymentService.withdrawMoneyPaymentPaymentTypeWithdrawPost(paymentType, amount, address, currencyIso);
    } catch (e) {
      throw new Error('Can\'t execute postPaymentWithdraw api2 method');
    }
  }

  public checkCard(value: string): Observable<Response> {
    try {
      return this.paymentService.checkCardNumberPaymentCheckCardGet(value);
    } catch (e) {
      throw new Error('Can\'t execute checkCardNumberPaymentCheckCardGet api2 method');
    }
  }

  public checkCryptoWallet(value: string): Observable<Response> {
    try {
      return this.paymentService.checkCryptoAddrPaymentCheckAddrGet(value);
    } catch (e) {
      throw new Error('Can\'t execute checkCryptoAddrPaymentCheckAddrGet api2 method');
    }
  }
}
