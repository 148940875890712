import { AuthRepository } from '@core/repositories';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  EmailResponse,
  ScryptChallengeResponse,
  SendEmailRequest,
  SessionResponse,
  VerifyEmailRequest,
} from '@core/models';
import { AuthService } from '@api/backend';
import { Response } from '@core/models/global.model';

@Injectable()
export class AuthWebRepository extends AuthRepository {

  constructor(
    private authService: AuthService,
  ) { super(); }

  public postAuthSession(): Observable<SessionResponse> {
    try {
      return this.authService.newSessionAuthSessionPost() as Observable<SessionResponse>;
    } catch {
      throw new Error('Can\'t execute postAuthSession api2 method');
    }
  }

  public deleteAuthSession(): Observable<Response> {
    try {
      return this.authService.deleteSessionAuthSessionDelete();
    } catch {
      throw new Error('Can\'t execute deleteAuthSession api2 method');
    }
  }

  public postAuthEmail(body: SendEmailRequest): Observable<EmailResponse> {
    try {
      return this.authService.sendEmailCodeAuthEmailPost(body);
    } catch {
      throw new Error('Can\'t execute postAuthPhoneVerify api2 method');
    }
  }

  public postAuthEmailVerify(body: VerifyEmailRequest): Observable<SessionResponse> {
    try {
      return this.authService.verifyEmailCodeAuthEmailVerifyPost(body);
    } catch {
      throw new Error('Can\'t execute postAuthSessionToken api2 method');
    }
  }

  public postAuthSessionToken(): Observable<SessionResponse> {
    try {
      return this.authService.refreshAccessTokenAuthSessionTokenPost() as Observable<SessionResponse>;
    } catch {
      throw new Error('Can\'t execute postAuthSessionToken api2 method');
    }
  }

  public postAuthSignEula(): Observable<SessionResponse> {
    try {
      return this.authService.signEulaAuthSignEulaPost();
    } catch {
      throw new Error('Can\'t execute postAuthSignEula api2 method');
    }
  }

  public getAuthChallenge(): Observable<ScryptChallengeResponse> {
    try {
      return this.authService.getScryptChallengeAuthScryptChallengeGet();
    } catch {
      throw new Error('Can\'t execute getAuthChallenge api2 method');
    }
  }

}
