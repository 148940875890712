import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { layoutFeatureKey, layoutReducer } from '@store/layout';
import { EffectsModule } from '@ngrx/effects';


@NgModule({
  imports: [
    StoreModule.forFeature(layoutFeatureKey, layoutReducer),
    EffectsModule.forFeature([]),
  ],
  exports: [
    StoreModule,
  ],
})
export class LayoutFeatureModule { }
