import { inject, Injectable } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { createSelector, Store } from '@ngrx/store';
import { sessionSelectors } from '@store/session';
import { userActions, userSelectors } from '@store/user';
import { filter } from 'rxjs/operators';
import { CookieService } from '@core/services/cookies/cookie.service';
import { OverlayService } from '@app/services/dialog/overlay.service';
import { ConfirmAgeComponent } from '@public/auth/confirm-age/confirm-age.component';
import { AGE_CONFIRM_TOKEN } from '@data/const';

const selectIsRequireUser = createSelector(
  sessionSelectors.selectSessionUserId,
  userSelectors.selectUserData,
  (userId, userData) => !!userId && !userData,
);

const isAgeConfirmed = (cookie: CookieService, overlay: OverlayService): Observable<boolean> => {
  return cookie.getAsync(AGE_CONFIRM_TOKEN)
    .pipe(
      map(isConfirmed => {
        if (!isConfirmed) {
          overlay.openComponent(ConfirmAgeComponent, { needsCallBackBeforeClose: true });
        }
        return Boolean(isConfirmed);
      }),
      filter(Boolean),
    );
};

const isRequiredToLoadUser = (store: Store) => store.select(selectIsRequireUser)
  .pipe(
    map(isRequire => {
      if (isRequire) {
        store.dispatch(userActions.getUser());
      }
      return !isRequire;
    }),
    filter(Boolean),
    take(1),
  );

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {

  private cookieService = inject(CookieService);

  private overlayService = inject(OverlayService);

  private store = inject(Store);


  canLoad(): Observable<boolean> {
    return isAgeConfirmed(this.cookieService, this.overlayService);
  }

  loadUser(): Observable<boolean> {
    return isRequiredToLoadUser(this.store);
  }
}
