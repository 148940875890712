import { Component, ElementRef, forwardRef, Input, QueryList, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sens-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OtpComponent),
      multi: true,
    },
  ],
})
export class OtpComponent implements ControlValueAccessor {
  @Input() otpLength: number = 6;

  @ViewChildren('digit') inputs!: QueryList<ElementRef>;

  private get digitInputs(): HTMLInputElement[] {
    return Array.from(this.inputs).map(elRef => elRef.nativeElement);
  }

  public otpDigits: Array<string> = Array(this.otpLength).fill('');

  private propagateChange: (_: any) => void = () => {};

  constructor(private el: ElementRef) {}

  writeValue(value: string): void {
    this.otpDigits = value?.split('') || this.otpDigits;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  onKeyup(event: any, index: number): void {
    const digit = event.target.value;
    this.otpDigits[index] = digit;

    if (index < this.otpLength - 1 && digit !== '') {
      const nextInput = this.digitInputs[index + 1];
      nextInput.focus();
    }

    this.propagateChange(this.otpDigits.join(''));
  }

  onBackspace(index: number): void {
    if (this.otpDigits[index] === '' && index > 0) {
      const prevInput = this.digitInputs[index - 1];
      prevInput.focus();
      prevInput.select();
    }
  }

  onPaste(event: ClipboardEvent, index: number) {
    event.preventDefault();

    let pastedText = event.clipboardData?.getData('text') ?? '';
    pastedText = pastedText.replace(/[\D]/g, '');

    if (!pastedText) {
      return;
    }

    let nextInput: HTMLInputElement = this.digitInputs[index];

    for (let i = 0; i < pastedText.length; i++) {
      if (this.digitInputs[index + i] === undefined) {
        break;
      }
      this.digitInputs[index + i].value = pastedText[i];
      this.otpDigits[index + i] = pastedText[i];

      nextInput = this.digitInputs[index + i + 1] ?? this.digitInputs[0];
    }

    nextInput?.focus();

    this.propagateChange(this.otpDigits.join(''));
  }

  protected readonly Array = Array;
}
