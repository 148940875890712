import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Session } from '@core/models';
import { sessionFeatureKey, SessionState } from '@store/session/session.reducer';

const selectSessionFeature = createFeatureSelector<SessionState>(sessionFeatureKey);

const selectSessionToken = createSelector(
  selectSessionFeature,
  (state) => ({
    user_id: state.user_id,
    access_token: state.access_token,
    refresh_token: state.refresh_token,
  } as Session),
);

const selectSessionUserId = createSelector(
  selectSessionFeature,
  (state) => state.user_id,
);

const selectIsSetUser = createSelector(
  selectSessionFeature,
  (state) => !!state.user_id,
);

export const sessionSelectors = {
  selectSessionToken,
  selectSessionUserId,
  selectIsSetUser,
};



