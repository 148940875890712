export enum ETariff {
  M1 = 'm1',
  M3 = 'm3',
}

export enum ETrial {
  T0 = 't0',
  T1 = 't1',
  T3 = 't3',
  T7 = 't7',
}

export const TrialValues: ETrial[] = Object.values(ETrial);

export type TariffValue = `${ETariff | ETrial}`;

export type TariffPrice = `${ETariff}_price`;

export type TariffLimit = {
  min: number,
  max: number,
};

export type TariffPriceLimits = {
  [ key in TariffPrice ]: TariffLimit
};

export const TARIFF_PRICE_LIMITS: TariffPriceLimits = {
  m1_price: {
    min: 4.99,
    max: 49.99,
  },
  m3_price: {
    min: 14.99,
    max: 199.99,
  },
};
