import { Injectable } from '@angular/core';
import { MessageRepository } from '@core/repositories';
import {
  BaseResponse,
  CreateMessage,
  CreateMessageDonate,
  DialogResponse,
  FullDialogListResponse,
  FullDialogResponse,
  MessageService,
  MessagesListResponse,
} from '@api/backend';
import { Observable } from 'rxjs';

@Injectable()
export class MessageWebRepository extends MessageRepository {

  constructor(
    private messageService: MessageService,
  ) {
    super();
  }

  public override getChats(page?: number): Observable<FullDialogListResponse> {
    try {
      return this.messageService.getDialogsMessageDialogsGet(page);
    } catch {
      throw new Error('Can\'t execute getChats api2 method');
    }
  }

  public override getHistory(dialogId: string, page?: number): Observable<MessagesListResponse> {
    try {
      return this.messageService.getMessageByDialogMessageHistoryDialogIdGet(dialogId, page);
    } catch {
      throw new Error('Can\'t execute getChats api2 method');
    }
  }

  public override postMessage(dialogId: string, body: CreateMessage): Observable<BaseResponse> {
    try {
      return this.messageService.createMessageMessageMessageDialogIdMessagePost(dialogId, body);
    } catch {
      throw new Error('Can\'t execute postMessage api2 method');
    }
  }

  public override postMessageDonate(dialogId: string, body: CreateMessageDonate): Observable<BaseResponse> {
    try {
      return this.messageService.createMessageDonateMessageDialogIdDonatePost(dialogId, body);
    } catch {
      throw new Error('Can\'t execute postMessageDonate api2 method');
    }
  }

  public override postMessageAttachment(dialogId: string, file?: File, text?: string ): Observable<BaseResponse> {
    try {
      return this.messageService.createMessageAttachmentMessageDialogIdAttachmentPost(dialogId, text, file);
    } catch {
      throw new Error('Can\'t execute postMessageAttachment api2 method');
    }
  }

  public override postMessageDialog(userId: string): Observable<DialogResponse> {
    try {
      return this.messageService.createMessageDialogMessageUserIdDialogPost(userId);
    } catch {
      throw new Error('Can\'t execute postMessageDialog api2 method');
    }
  }

  public override getMessageDialog(dialogId: string): Observable<FullDialogResponse> {
    try {
      return this.messageService.getDialogByIdMessageDialogDialogIdGet(dialogId);
    } catch {
      throw new Error('Can\'t execute getMessageDialog api2 method');
    }
  }


}
