export const EMPTY_STRING = '';

export const PAYMENT_WITHDRAW_ADDRESS_ASYNC_ERROR_KEY = 'withdrawAddressInvalid';

export enum HttpKeyEnum {
  FORBIDDEN = 'forbidden',
  HTTP_AUTHORIZATION_EXPIRED_KEY = 'authorization.expired',
  HTTP_AUTHORIZATION_REQUIRED_KEY = 'authorization.required',
  HTTP_AUTHORIZATION_INVALID = 'authorization.invalid',
  HTTP_AUTHORIZATION_SESSION_NOT_FOUND = 'session_not_found',
}

export const GLOBAL_ERROR_CODES = [ 404 ];
export const LOCAL_ERROR_CODES = [ 403, 422, 429, 500 ];
export const SPECIAL_CODES = [ ...GLOBAL_ERROR_CODES, ...LOCAL_ERROR_CODES ];

export const COOKIE = {
  EXPIRE: 7,
  PATH: '/',
};

export const AGE_CONFIRM_TOKEN = 'age_c';
