import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[sensInfiniteScrollLocal]',
})
export class InfiniteScrollLocalDirective {

  private targetToFireValue: number = 0;

  private isFired = false;

  @Input() set targetToFire(value: string) {
    const numberValue = Number.parseInt(value);
    if (!value.endsWith('px') || Number.isNaN(numberValue)) {
      // eslint-disable-next-line no-console
      console.error('sensInfiniteScrollLocal targetToFire value should be string in px: \'100px\'');
    } else {
      this.targetToFireValue = numberValue;
    }
  }

  @Output() scrolled = new EventEmitter<void>();

  @HostListener('scroll', [ '$event.target'] )
  scrollListener(target: HTMLElement) {
    const { offsetHeight, scrollTop, scrollHeight } = target;
    if (!this.targetToFireValue) {
      this.targetToFireValue = offsetHeight * 0.20;
    }
    const scrolledUntilEnd = scrollHeight - scrollTop - offsetHeight;
    if (scrolledUntilEnd <= this.targetToFireValue) {
      if (!this.isFired) {
        this.isFired = true;
        this.scrolled.emit();
      }
    } else {
      this.isFired = false;
    }
  }

}
