import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { MediaListResponse, MediaRequest, MediaResponse, PresignedRequest, Response } from '@core/models';
import { MediaService, ViewData } from '@api/backend';
import { MediaRepository } from '@core/repositories/media.repository';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MediaWebRepository extends MediaRepository {

  constructor(
    private mediaService: MediaService,
    private httpClient: HttpClient,
  ) { super(); }

  public getMediaRecommendationFeed(page?: number): Observable<MediaListResponse> {
    try {
      return this.mediaService.mediaRecommendationMediaRecommendationGet(page) ;
    } catch {
      throw new Error('Can\'t execute getMediaRecommendationFeed api2 method');
    }
  }

  public deleteMediaById(media_id: string): Observable<Response> {
    try {
      return this.mediaService.deleteMediaMediaMediaIdDelete(media_id);
    } catch {
      throw new Error('Can\'t execute deleteMediaById api2 method');
    }
  }

  public getMediaById(media_id: string): Observable<MediaResponse> {
    try {
      return this.mediaService.getMediaMediaMediaIdGet(media_id);
    } catch {
      throw new Error('Can\'t execute getMediaById api2 method');
    }
  }

  public getMediaFollowingFeed(page?: number): Observable<MediaListResponse> {
    try {
      return this.mediaService.mediaFollowingMediaFollowingGet(page);
    } catch {
      throw new Error('Can\'t execute getMediaFollowingFeed api2 method');
    }
  }

  public postMediaPhoto(body: MediaRequest): Observable<MediaResponse> {
    try {
      return this.mediaService.createMediaPhotoMediaPhotoPost(
        body.isFree,
        body.mediaName ?? undefined,
        body.mediaDescription ?? undefined,
        body.preview,
        body.photo1,
        body.photo2,
        body.photo3,
        body.photo4,
        body.photo5,
        body.photo6,
      );
    } catch {
      throw new Error('Can\'t execute postMediaPhoto api2 method');
    }
  }

  public postMediaVideo(body: MediaRequest): Observable<MediaResponse> {
    try {
      return this.mediaService.createMediaVideoMediaVideoPost(
        body.isFree,
        body.mediaName ?? undefined,
        body.mediaDescription ?? undefined,
        body.preview,
      );
    } catch {
      throw new Error('Can\'t execute postMediaVideo api2 method');
    }
  }

  public uploadVideo(body: PresignedRequest, file: Blob): Observable<Response> {
    try {
      const formData = new FormData();
      for (const fieldsKey in body) {
        if (fieldsKey !== 'url') {
          formData.append(fieldsKey.replaceAll('_', '-'), (body as never)[fieldsKey]);
        }
      }
      formData.append('file', file as Blob);
      return this.httpClient.post(body.url, formData, {});
    } catch {
      throw new Error('Can\'t execute uploadVideo api2 method');
    }
  }

  postMediaHide(media_id: string): Observable<Response> {
    try {
      return this.mediaService.postMediaHideMediaMediaIdHidePost(media_id);
    } catch {
      throw new Error('Can\'t execute postMediaHide api2 method');
    }
  }

  postMediaLike(media_id: string): Observable<Response> {
    try {
      return this.mediaService.postMediaLikeMediaMediaIdLikePost(media_id);
    } catch {
      throw new Error('Can\'t execute postMediaHide api2 method');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  postAuthorShare(userId: string): Observable<Response> {
    try {
      return of({} as Response);
    } catch {
      throw new Error('Can\'t execute postMediaHide apiXXX method');
    }
  }

  postMediaShare(media_id: string): Observable<Response> {
    try {
      return this.mediaService.postMediaShareMediaMediaIdSharePost(media_id);
    } catch {
      throw new Error('Can\'t execute postMediaHide api2 method');
    }
  }

  postMediaUnlike(media_id: string): Observable<Response> {
    try {
      return this.mediaService.postMediaUnlikeMediaMediaIdUnlikePost(media_id);
    } catch {
      throw new Error('Can\'t execute postMediaHide api2 method');
    }
  }

  public postMediaComplaint(media_id:string, complaintTypeId: string, comment?: string): Observable<Response> {
    try {
      return this.mediaService.postMediaComplaintMediaMediaIdComplaintPost(media_id, {
        complaint_type_id: complaintTypeId,
        comment,
      });
    } catch {
      throw new Error('Can\'t execute postMediaComplaint api2 method');
    }
  }

  public postMediaView(mediaId: string, view: ViewData): Observable<Response> {
    try {
      return this.mediaService.postMediaViewMediaMediaIdViewPost(mediaId, view);
    } catch {
      throw new Error('Can\'t execute postMediaView api2 method');
    }
  }

}
