import { createAction, props } from '@ngrx/store';

export enum ESplashActions {
  ToggleSplashScreen = '[Splash] Toggle Splash Screen',
}

export const toggleSplashScreen = createAction(
  ESplashActions.ToggleSplashScreen,
  props<{ visible: boolean }>(),
);

