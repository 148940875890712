import { Injectable } from '@angular/core';
import { sensBreakpointValues } from '@data/enums';
import { distinctUntilChanged, tap } from 'rxjs';
import * as LayoutActions from '@store/layout/layout.actions';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class BreakpointsService {

  readonly breakpoint$ = this.breakpointObserver
    .observe(sensBreakpointValues)
    .pipe(
      distinctUntilChanged(),
      tap(() => {
        const breakpoint = sensBreakpointValues.find(bp => this.breakpointObserver.isMatched(bp));
        if (breakpoint) {
          this.store.dispatch(LayoutActions.setBreakpoint({ breakpoint }));
        }
      }),
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store,
  ) {

  }

  public init() {
    this.breakpoint$.pipe(untilDestroyed(this)).subscribe();
  }
}

