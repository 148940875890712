<div class="container">
  <div class="panel auth-panel">
    <button
        type="button"
        class="btn btn--icon auth-panel__close-btn"
        (click)="onCloseModal()"
    >
      <sens-icon path="sprite.svg#icon-close" />
    </button>
    <div *ngIf="state() as state" class="panel__body">
      <button
        *ngIf="state.step === authStep.CODE"
        type="button"
        class="btn btn--icon panel__back"
        (click)="onGoBack()"
      >
        <sens-icon path="sprite.svg#icon-arrow-left" />
      </button>
      <img
        class="auth-panel__logo"
        [ngSrc]="'/assets/img/logo.svg'"
        alt="Sens"
        width="80"
        height="33"
      />

      <h2 class="title-1 auth-panel__title">
        <ng-template [ngIf]="state.step === authStep.START">
          {{ 'auth:title.start' | i18nextEager }}
        </ng-template>
        <ng-template [ngIf]="state.step === authStep.CODE">
          {{ 'auth:title.code' | i18nextEager }}
        </ng-template>
      </h2>

      <p class="text-1 auth-panel__moto">
        <ng-template [ngIf]="state.step === authStep.START">
          <span
            [innerHTML]="'auth:moto.start' | i18nextEager"
          ></span>
        </ng-template>
        <ng-template [ngIf]="state.step === authStep.CODE">
          <span
            [innerHTML]="'auth:moto.code' | i18nextEager"
          ></span>
        </ng-template>
      </p>

      <form
        *ngIf="authForm() as authForm"
        class="form"
        [ngrxFormState]="authForm"
      >
        <ng-template [ngIf]="state.step === authStep.START">
          <div class="form__group">
            <div
              class="form__control"
              [class.valid]="authForm.controls.email.isValid  && !state.error"
            >
              <input
                type="email"
                [readOnly]="state.loading || state.step !== authStep.START"
                (input)="onInput()"
                [ngrxFormControlState]="authForm.controls.email"
                [placeholder]="'auth:placeholder.email' | i18nextEager"
              >
              <sens-icon
                *ngIf="authForm.controls.email.isValid && !state.error"
                class="form__icon"
                path="sprite.svg#icon-check"
                [color]="'success'"
                [size]="20"
              />

              <sens-icon
                *ngIf="state.error"
                class="form__icon"
                path="sprite.svg#icon-cancel"
                [color]="'error'"
                [size]="20"
              />
            </div>
            <p *ngIf="state.error" class="form__error">
              <ng-container *ngIf="state.error.error.errors.length; else defaultErrorText">
                {{ 'error:' + state.error.error.errors[0].error_key | i18nextEager }}
              </ng-container>
            </p>
          </div>

          <div class="form__group">
            <div class="form__checkbox">
              <input
                #confirmAge
                type="checkbox"
                name="confirm_age"
                id="confirmAge"
                [ngrxFormControlState]="authForm.controls.age"
              >
              <label for="confirmAge">
                {{ 'auth:text.start' | i18nextEager }}
              </label>
            </div>
          </div>


          <button
            type="button"
            class="btn btn--primary btn--lg btn--full"
            [disabled]="!authForm.controls.email.isValid || state.loading || state.error || !confirmAge.checked"
            (click)="getCode()"
          >
            {{ 'auth:btn.start' | i18nextEager }}
          </button>
        </ng-template>

        <ng-template [ngIf]="state.step === authStep.CODE">
          <div class="form__group">
            <sens-otp [ngrxFormControlState]="authForm.controls.code"/>
            <p *ngIf="state.error" class="form__error">
              <ng-container *ngIf="state.error.error.errors.length; else defaultErrorText">
                {{ 'error:verification_code.invalid' | i18nextEager }}
              </ng-container>
            </p>
            <p class="form__text"
               [innerHTML]="'auth:text.code' | i18nextEager:{ email: authForm.controls.email.value }">
            </p>
          </div>
          <button
            type="button"
            class="btn btn--primary btn--lg btn--full"
            [disabled]="!authForm.controls.code.isValid || state.loading"
            (click)="verifyCode()"
          >
            {{ 'auth:btn.code' | i18nextEager }}
          </button>

          <div class="form__countdown">
            <ng-container *ngIf="countdownState?.action !== 'done'; else countdownDone">
              {{ 'auth:countdown.code' | i18nextEager }}
            </ng-container>
            <ng-template #countdownDone>
              {{ 'auth:countdown.request' | i18nextEager }}
              <button
                type="button"
                class="btn btn--link btn--info btn--inline"
                (click)="resendCode()"
              >
                <b>
                  {{ 'auth:countdown.btn' | i18nextEager }}
                </b>
              </button>
            </ng-template>
            <countdown
              #cd
              [config]="countdownConfig"
              (event)="countdownEvent($event)"
            ></countdown>
          </div>
        </ng-template>

        <ng-template [ngIf]="state.step === authStep.EULA">
          <div class="auth-panel__eula">
            <div class="panel panel--notice">
              <div class="panel__body">
                <sens-icon color="primary" path="sprite.svg#icon-circle-outline" />
                <p class="text-1">
                  {{ 'auth:title.eula' | i18nextEager }}
                </p>
              </div>
            </div>
            <div
              #eulaWrapperElRef
              class="panel panel--secondary auth-panel__agreement"
              (scroll)="onScrollDown(eulaWrapperElRef)"
            >
              <div class="panel__body">
                <ng-container *ngTemplateOutlet="eulaTemplate"></ng-container>
              </div>
            </div>
            <div *ngIf="canSignEula" class="form__checkbox">
              <input
                type="checkbox"
                name="confirm_eula"
                id="confirmEula"
                [value]="0"
                (input)="onEulaChange($event)"
              >
              <label for="confirmEula">
                {{ 'auth:text:eula' | i18nextEager }}
              </label>
            </div>

            <button
              type="button"
              class="btn btn--primary btn--lg btn--full"
              [disabled]="!canSignEula || !isCheckEulaSign"
              (click)="signEula()"
            >
              {{ 'auth:btn.eula' | i18nextEager }}
            </button>
          </div>
        </ng-template>
      </form>
    </div>
  </div>

  <ng-template #defaultErrorText>
    Something wrong
  </ng-template>

  <ng-template #eulaTemplate>
    <ng-template [ngIf]="localeId === 'ru-RU'" [ngIfElse]="anotherLocale">
      <p>
        Платформа SENS (далее – «SENS», «Платформа», «мы», «наш», «нас») представляет собой набор API, SDK,
        инструментов, плагинов, программного кода, технологий, контента и сервисов. Платформа поддерживается NUTSON EU
        LIMITED, регистрационный номер HE 426747, адрес зарегистрированного офиса: Thiseos, Egkomi, 2411, Nicosia,
        Cyprus. NUTSON EU LIMITED отвечает в том числе за взаимодействие с платежными системами, ассистирование при
        проведении транзакций, а также осуществления административных функций и функций финансового контроля, а также за
        предоставление Платформы, ее корректную работу, взаимодействие с пользователями и обработку персональных данных
        и обработку ваших персональных данных (смотрите
        <a [href]="'/assets/files/privacy-policy-ru.pdf'" target="_blank">Политику Конфиденциальности</a>).
      </p>
      <p>
        ПОЖАЛУЙСТА, ПРЕЖДЕ ЧЕМ ЗАРЕГИСТРИРОВАТЬСЯ И НАЧАТЬ ПОЛЬЗОВАТЬСЯ ПЛАТФОРМОЙ, ВНИМАТЕЛЬНО ОЗНАКОМЬТЕСЬ СО
        СЛЕДУЮЩИМИ УСЛОВИЯМИ ПОЛЬЗОВАНИЯ ПЛАТФОРМОЙ (данные Условия являются краткими изложением нашего
        <a [href]="'/assets/files/terms-of-use-ru.pdf'" target="_blank">Пользовательского соглашения</a> и
        <a [href]="'/assets/files/privacy-policy-ru.pdf'" target="_blank">Политики конфиденциальности</a>
        и ни в какой мере не заменяют положения полного документа):
      </p>
      <p>
        Данный сайт (Платформа) является платформой, где авторы могут публиковать свой контент ( видео и фото, прямые
        эфиры) на различные темы, а пользователи могут оплачивать просмотр этого Контента ( далее- Контент). Контент
        может содержать информацию, ссылки, изображения, комментарии и видео откровенного сексуального характера
        (совместно именуемые «материалы для взрослых»). Вы признаете, что вам известно, что некоторый Контент на
        Платформе содержит материалы для взрослых, и соглашаетесь с тем, что принимаете это условие при входе в
        приложение и просмотре Контента. Мы не будем нести ответственности перед вами, если вы понесете какие-либо
        убытки или ущерб в результате входа в Приложение и просмотра Контента, содержащего материалы для взрослых в
        рамках ваших соглашений с третьими лицами (например, в рамках трудового договора) или в нарушение любого
        применимого законодательства.
      </p>
      <p>
        ЕСЛИ ВЫ ЗДЕСЬ В ПОИСКАХ ДЕТСКОЙ ПОРНОГРАФИИ, ПОЖАЛУЙСТА, ПОКИНЬТЕ САЙТ. МЫ НЕ ДОПУСКАЕМ ДЕТСКУЮ ПОРНОГРАФИЮ В
        ЛЮБОМ ВИДЕ ИЛИ ОБРАЗЕ, И ВЫРАЖЕННУЮ ЛЮБЫМ СПОСОБОМ ИЛИ ФОРМОЙ, А ТАКЖЕ ЛЮБОЙ КОНТЕНТ, ПООЩРЯЮЩИЙ СОЗДАНИЕ,
        РАСПРОСТРАНЕНИЕ, ИСПОЛЬЗОВАНИЕ, ВОСПРОИЗВЕДЕНИЕ ДЕТСКОЙ ПОРНОГРАФИИ. ЛЮБЫЕ ПОПЫТКИ ПО ЭКСПЛУАТАЦИИ И
        ОПОРОЧИВАНИЮ ДЕТЕЙ, А ТАКЖЕ НАРУШЕНИЯ ИХ ПРАВ, БУДУТ ПЕРЕДАНЫ СООТВЕТСТВУЮЩИМ ОРГАНАМ ВЛАСТИ.
      </p>
      <p>
        ДОСТУП К ДАННОМУ САЙТУ ВОЗМОЖЕН ЛИШЬ СОВЕРШЕННОЛЕТНИМ, ДЕЕСПОСОБНЫМИ И ПРАВОСПОСОБНЫМ ЛИЦАМ, ПОЛНОСТЬЮ
        ОСОЗНАЮЩИМ СВОИ ДЕЙСТВИЯ. ПОЖАЛУЙСТА, ПОКИНЬТЕ ПЛАТФОРМУ, ЕСЛИ:
        <br>(i) Вам <b><u>НЕ ИСПОЛНИЛОСЬ</u></b> 18 лет или Вы не достигли совершеннолетия на территориях, где Вы будете
        просматривать Контент, а также пользоваться Платформой, в зависимости от того, что наступает позже (<b>«Возраст
        совершеннолетия»</b>);
        <br>(ii) такой Контент является для вас оскорбительным и неприемлемым, вы знаете, что Контент может нанести вам
        какую-либо травму или Вам неприятно смотреть на такой Контент или любые его части;
        <br>(iii) просмотр Контента нарушает законы страны, на территории которого Вы будете его смотреть.
      </p>
      <p>
        Продолжая и подтверждая, что вам 18 лет и старше, вы самостоятельно подтверждаете доступ к просмотру материалов
        для взрослых.
      </p>
      <p>
        Вы самостоятельно несете полную ответственность за исполнение применимого к вам законодательства, касающегося
        материалов для взрослых. Вы берете на себя ответственность за любые Ваши действия на сайте, личные последствия
        от использования сайта и соблюдение общественных и социальных норм. Создатели этого сайта и поставщики услуг не
        несут никакой ответственности за Ваш выбор - продолжить использование этого сайта.
      </p>
      <p>
        <b>Персональные данные</b>
        <br>На данном сайте мы собираем следующую информацию:
        <br>Ваши ФИО.
        <br>Ваш номер телефона.
        <br>Ваш email.
      </p>
      <p>
        <b>Cookies</b>
        <br>На данном веб-сайте используются файлы cookie. Продолжая просматривать наш веб-сайт, вы соглашаетесь с
        использованием файлов cookie. Более подробную информацию о сборе персональных данных вы можете найти
        <a href="./assets/files/terms-of-use-ru.pdf" target="_blank">здесь</a>.
      </p>
    </ng-template>
    <ng-template #anotherLocale>
      <p>
        The SENS Platform (hereinafter referred to as "SENS", "Platform", "we", "our", "us") is a set of APIs, SDKs,
        tools, plugins, code, technologies, content and services. The platform is supported by NUTSON EU LIMITED,
        registration number HE 426747, registered office address: Thiseos, Egkomi, 2411, Nicosia, Cyprus. NUTSON EU
        LIMITED is responsible, among other things, for interacting with payment systems, assisting in transactions, as
        well as performing administrative and financial control functions, as well as for providing the Platform, its
        correct operation, interaction with users and processing personal data and processing your personal data (see
        <a [href]="'/assets/files/privacy-policy-en.pdf'" target="_blank">Privacy Policy</a>).
      </p>
      <p>
        PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE REGISTERING AND USING THE PLATFORM (these Terms
        are a summary of our
        <a [href]="'/assets/files/terms-of-use-en.pdf'" target="_blank">User Agreement</a> and <a
          [href]="'/assets/files/privacy-policy-en.pdf'" target="_blank">Privacy Policy</a> and in no way replace the
        provisions of the full document):
      </p>
      <p>
        This site (Platform) is a platform where authors can publish their content (videos and photos, live broadcasts)
        on various topics, and users can pay to view this Content (hereinafter referred to as Content). Content may
        contain sexually explicit information, links, images, comments, and videos (collectively, "Adult Content"). You
        acknowledge that you are aware that some Content on the Platform contains adult content and you agree that you
        accept this by logging into the application and viewing the Content. We will not be liable to you if you incur
        any loss or damage as a result of logging into the Application and viewing Content containing adult content
        under your agreements with third parties (for example, under an employment contract) or in violation of any
        applicable legislation.
      </p>
      <p>
        IF YOU ARE HERE LOOKING FOR CHILD PORNOGRAPHY, PLEASE LEAVE THE SITE. WE DO NOT ACCEPT CHILD PORNOGRAPHY IN ANY
        FORM OR IMAGE, OR EXPRESSED IN ANY WAY OR FORM, AS WELL AS ANY CONTENT THAT ENCOURAGES THE CREATION,
        DISTRIBUTION, USE, REPRODUCTION OF CHILD PORNOGRAPHY. ANY ATTEMPTS OF EXPLOITATION AND DEFAULTING OF CHILDREN,
        AS WELL AS VIOLATIONS OF THEIR RIGHTS, WILL BE REFERRED TO THE APPROPRIATE AUTHORITIES.
      </p>
      <p>
        ACCESS TO THIS SITE IS POSSIBLE ONLY BY AGED, ABLE AND LEGAL PERSONS WHO ARE FULLY UNDERSTANDING THEIR ACTIONS.
        PLEASE LEAVE THE PLATFORM IF:
        <br>(i) you are UNDER 18 years of age or under the age of majority in the territories where you will view
        Content and use the Platform, whichever is later (“Age of Legal majority”);
        <br>(ii) such Content is offensive and unacceptable to you, you know that the Content may cause you any injury
        or you find it unpleasant to look at such Content or any part of it;
        <br>(iii) viewing the Content violates the laws of the country in which you will be viewing it.
      </p>
      <p>
        By continuing and confirming that you are 18 years of age or older, you yourself confirm access to viewing adult
        sexually explicit material.
      </p>
      <p>
        You are solely responsible for complying with the laws applicable to you regarding adult content. You assume
        responsibility for any of your actions on the site, personal consequences from the use of the site and
        compliance with public and social norms. The creators of this site and the service providers assume no
        responsibility for your choice to continue using this site.
      </p>
      <p>
        <b>Personal data</b>
        <br>On this website, we collect the following information:
        <br>Your full name.
        <br> phone number.
        <br>Your email.
      </p>
      <p>
        <b>Cookies</b>
        <br>This website uses cookies. By continuing to browse our website, you agree to our use of cookies. You can
        find more information about the collection of personal data
        <a href="./assets/files/terms-of-use-en.pdf" target="_blank">here</a>.
      </p>
    </ng-template>
  </ng-template>

</div>
