import {
  BaseInvoiceLinkResponse,
  BaseKYCLinkResponse,
  BaseUserList,
  BaseUserListResponse,
  BaseUserResponse, KYCLink,
  UpdateUser,
  UserResponse as ApiUserResponse,
  InvoiceLink as InvoiceLinkApi,
} from '@api/backend';

export type User = ApiUserResponse;
export type UserResponse = BaseUserResponse;
export type UserListResponse = BaseUserListResponse;
export type UserList = BaseUserList;
export type UserRequest = UpdateUser;
export type UserKycLink = KYCLink;
export type UserKycLinkResponse = BaseKYCLinkResponse;
export type InvoiceLinkResponse = BaseInvoiceLinkResponse;
export type InvoiceLink = InvoiceLinkApi;

export enum UserCardSizeTypes {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}
