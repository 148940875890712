import { Observable } from 'rxjs';
import { Response } from '@core/models';
import { MessagesResponse } from '@core/models/message.model';
import {
  CreateMessage,
  CreateMessageDonate,
  DialogResponse,
  FullDialogListResponse,
  FullDialogResponse,
} from '@api/backend';

export abstract class MessageRepository {
  /**
   * @method GET
   * @return Observable<UserListResponse>
   */
  public abstract getChats(page?: number): Observable<FullDialogListResponse>;

  /**
   * @method GET
   * @return Observable<MessagesResponse>
   */
  public abstract getHistory(userId: string, page?: number): Observable<MessagesResponse>;

  /**
   * @method POST
   * @return Observable<Response>
   */
  public abstract postMessage(dialogId: string, body: CreateMessage): Observable<Response>;

  /**
   * @method POST
   * @return Observable<Response>
   */
  public abstract postMessageDonate(userId: string, body: CreateMessageDonate): Observable<Response>;

  /**
   * @method POST
   * @return Observable<Response>
   */
  public abstract postMessageAttachment(dialogId: string, file?: File, text?: string ): Observable<Response>;

  /**
   * @method POST
   * @return Observable<Response>
   */
  public abstract postMessageDialog(userId: string): Observable<DialogResponse>;

  /**
   * @method POST
   * @return Observable<Response>
   */
  public abstract getMessageDialog(dialogId: string): Observable<FullDialogResponse>;
}
