import {
  EmailResponse,
  ScryptChallengeResponse,
  SendEmailRequest,
  SessionResponse,
  VerifyEmailRequest,
  Response,
} from '@core/models';
import { Observable } from 'rxjs';

export abstract class AuthRepository {

  /**
   * @method POST
   * @return Observable<SessionResponse>
   */
  public abstract postAuthSession(): Observable<SessionResponse>;

  /**
   * @method POST
   * @return Observable<SessionResponse>
   */
  public abstract postAuthSignEula(): Observable<SessionResponse>;

  /**
   * @method DELETE
   * @return Observable<Response>
   */
  public abstract deleteAuthSession(): Observable<Response>;

  /**
   * @method POST
   * @param body SendEmailRequest
   * @return Observable<EmailResponse>
   */
  public abstract postAuthEmail(body: SendEmailRequest): Observable<EmailResponse>;

  /**
   * @method POST
   * @param body VerifyEmailRequest
   * @return Observable<SessionResponse>
   */
  public abstract postAuthEmailVerify(body: VerifyEmailRequest): Observable<SessionResponse>;

  /**
   * @method POST
   * @return Observable<SessionResponse>
   */
  public abstract postAuthSessionToken(): Observable<SessionResponse>;

  /**
   * @method GET
   * @return Observable<ScryptChallengeResponse>
   */
  public abstract getAuthChallenge(): Observable<ScryptChallengeResponse>;
}
