import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DialogRef } from '@app/services/dialog/dialog-ref.class';
import { CookieService } from '@core/services/cookies/cookie.service';
import { AGE_CONFIRM_TOKEN } from '@data/const';
import { Router } from '@angular/router';

@Component({
  selector: 'sens-confirm-age',
  templateUrl: './confirm-age.component.html',
  styleUrls: ['./confirm-age.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmAgeComponent {

  private router = inject(Router);

  public isChecked = false;

  private dialogRef: DialogRef = inject(DialogRef);

  private cookieService: CookieService = inject(CookieService);

  public accept() {
    this.isChecked = true;
    this.cookieService.set(AGE_CONFIRM_TOKEN, String(true));
    this.dialogRef.closeImmediately();
  }

  public reject() {
    // window.location.href = 'https://iqsha.ru/';
  }
}
