import { Observable } from 'rxjs';
import { ComplaintListResponse } from '@core/models';
import { SettingsModel } from '@core/models/settings.model';
import { CentrifugoSettingsResponse } from '@api/backend';

export abstract class CommonRepository {
  /**
   * @method GET
   * @return Observable<SettingsModel>
   */
  public abstract getCommonSettings(): Observable<SettingsModel>;
  /**
   * @method GET
   * @return Observable<ComplaintListResponse>
   */
  public abstract getCommonComplaints(): Observable<ComplaintListResponse>;

  /**
   * @method GET
   * @return Observable<CentrifugoSettingsResponse>
   */
  public abstract getCommonCentrifugo(): Observable<CentrifugoSettingsResponse>;
}
