import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { VerifyGuard } from '@core/guards/verify.guard';

const routes: Routes = [
  // {
  //   path: 'login',
  //   loadChildren: () => import('@public/auth/auth.module').then(m => m.AuthModule),
  //   canMatch: [() => inject(LoginGuard).canLoad()],
  // },
  {
    path: 'verify',
    loadChildren: () => import('@public/verify/verify.module').then(m => m.VerifyModule),
    canMatch: [() => inject(VerifyGuard).canLoad()],
  },
  {
    path: '',
    loadChildren: () => import('@private/private.module').then(m => m.PrivateModule),
    canMatch: [
      () => inject(AuthGuard).canLoad(),
      () => inject(AuthGuard).loadUser(),
    ],
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    // enableTracing: true,
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
