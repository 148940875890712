import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';

/**
 * A reference to the dialog itself.
 * Can be injected into the component added to the overlay and then used to close itself.
 */
export class DialogRef {

  private closeRequestSubject = new Subject<unknown>();

  private afterClosedSubject = new Subject<unknown>();

  constructor(
    private overlayRef: OverlayRef,
  ) {}

  public close(result?: unknown) {
    this.closeRequestSubject.next(result ?? true);
  }

  /**
   * Closes the overlay. You can optionally provide a result.
   */
  public closeImmediately(result?: unknown) {
    this.overlayRef.dispose();
    this.afterClosedSubject.next(result);
    this.closeRequestSubject.complete();
    this.afterClosedSubject.complete();
  }

  /**
   * An Observable that notifies when the overlay has closed
   */
  public afterClosed(): Observable<unknown> {
    return this.afterClosedSubject.asObservable();
  }

  public closureRequested(): Observable<unknown> {
    return this.closeRequestSubject.asObservable();
  }
}
