import {
  BaseScryptChallengeResponse,
  BaseSendEmailResponse,
  SendEmail,
  SendEmailResponse,
  VerifyEmail,
  ScryptChallengeResponse as ApiScryptChallengeResponse,
} from '@api/backend';

export enum AuthStep {
  START = 1,
  CODE = 2,
  EULA = 3,
}

export type EmailResponse = BaseSendEmailResponse;
export type Email = SendEmailResponse;
export type VerifyEmailRequest = VerifyEmail;
export type SendEmailRequest = SendEmail;
export type ScryptChallengeResponse = BaseScryptChallengeResponse;
export type ScryptChallenge = ApiScryptChallengeResponse;

