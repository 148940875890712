import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';
import { Provider } from '@angular/core';
import { isEqual } from 'lodash';

export class AppRouteReuseStrategy implements BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // return (future.routeConfig === curr.routeConfig) || future.data.reuseComponent;
    // return (future.routeConfig === curr.routeConfig) && !future.data['doNotReuseComponent'];
    const isChangedRoutes = future.routeConfig !== curr.routeConfig;
    const isForbiddenToReuse = future.data['doNotReuseComponent'];
    /**
     * Исключает перезагрузку страницы при смене фрагмента
     */
    const isChangedParams = !isEqual(future.params, curr.params);
    return !isChangedRoutes && !(isForbiddenToReuse && isChangedParams);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void;
  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle | null): void { }
}

export const ROUTE_REUSE_STRATEGY_PROVIDER: Provider = { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy };
