/**
 * Sens
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PresignedParams } from './presignedParams';

export interface FapResponse {
  fap_id: string;
  fap_name: string;
  fap_description?: string;
  author_id: string;
  user_name?: string;
  person_name?: string;
  user_avatar_url?: string;
  preview_url?: string;
  likes?: number;
  shares?: number;
  views?: number;
  reposts?: number;
  is_liked?: boolean;
  created_at?: any | null;
  video_url?: string;
  presigned_params?: PresignedParams;
  is_deleted?: boolean;
  is_blocked?: boolean;
}
