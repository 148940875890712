import { createAction, props } from '@ngrx/store';
import { SensBreakpoint } from '@data/enums';
import { Breakpoint } from '@data/breakpoints';

export enum ELayoutActions {
  SetBreakpoint = '[Layout] Set Breakpoint',
}

export const setBreakpoint = createAction(
  ELayoutActions.SetBreakpoint,
  props<{ breakpoint: SensBreakpoint }>(),
);


export const layoutActions = {
  setBreakpoint: createAction(
    '[Layout] Set breakpoint',
    props<{ breakpoint: Breakpoint }>(),
  ),
};


