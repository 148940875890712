/**
 * Sens
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ComplaintResponse } from './complaintResponse';

export interface ComplaintListResponse {
  media_complaints: Array<ComplaintResponse>;
  user_complaints: Array<ComplaintResponse>;
  stream_complaints: Array<ComplaintResponse>;
}
