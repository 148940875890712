import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { GetSession } from '@core/models';
import { Interceptor } from '@core/services/interceptor/interceptor';
import { HttpKeyEnum } from '@data/const';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, Observable, throwError } from 'rxjs';
import { FingerprintjsProAngularService } from '@fingerprintjs/fingerprintjs-pro-angular';

@Injectable()
export class ErrorInterceptor extends Interceptor implements HttpInterceptor {

  constructor(
    protected override store: Store,
    protected override actions: Actions,
    protected override fingerprintService: FingerprintjsProAngularService,
    @Inject(LOCALE_ID) protected override locale: string,
  ) {
    super(store, actions, fingerprintService, locale);
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      catchError(
        (error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            const errorKey = error.error?.errors?.[0]?.error_key;
            if (!errorKey) {
              return this.throwDisplayedError(error);
            }
            switch (error.status) {
              case HttpStatusCode.Unauthorized:
                switch (errorKey) {
                  case HttpKeyEnum.HTTP_AUTHORIZATION_EXPIRED_KEY:
                    return this.getSession(GetSession.REFRESH, request, next);
                  case HttpKeyEnum.HTTP_AUTHORIZATION_INVALID:
                    // this.store.dispatch(sessionActions.removeAuthSession());
                    return this.throwDisplayedError(error);
                  default:
                    return this.getSession(GetSession.NEW, request, next);
                }
              case HttpStatusCode.NotFound:
                switch (errorKey) {
                  case HttpKeyEnum.HTTP_AUTHORIZATION_SESSION_NOT_FOUND:
                    return this.getSession(GetSession.NEW, request, next);
                  default:
                    return this.throwDisplayedError(error);
                }
              default:
                return this.throwDisplayedError(error);
            }
          }
          return this.throwDisplayedError(error);
        },
      ),
    );
  }

  private throwDisplayedError(error: unknown) {
    return throwError(() => error);
  }
}
