import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { userFeatureKey, userReducer, UserEffects } from '@store/user';
import { UserRepository } from '@core/repositories';
import { UserWebRepository } from '@data/repositories/user-web.repository';


@NgModule({
  imports: [
    StoreModule.forFeature(userFeatureKey, userReducer),
    EffectsModule.forFeature([ UserEffects ]),
  ],
  exports: [
    StoreModule,
  ],
  providers: [
    { provide: UserRepository, useClass: UserWebRepository },
  ],
})
export class UserFeatureModule { }
