<div class="otp">
  <div class="otp__control"
       *ngFor="let _ of Array.from({length: otpLength}); let i = index">
    <input
      #digit
      type="tel" maxlength="1"
      [value]="otpDigits[i]"
      (keydown.backspace)="onBackspace(i)"
      (paste)="onPaste($event, i)"
      (input)="onKeyup($event, i)"
    />
  </div>
</div>

<ng-content />
