import { ComponentType, Overlay, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { DialogRef } from './dialog-ref.class';
import { DIALOG_DATA } from './dialog-tokens';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Dropdown } from '@app/services/dialog/components/dropdown/dropdown.component';
import { Popup } from '@app/services/dialog/components/popup/popup.component';
import { Curtain } from '@private/components/curtain/curtain.component';
import { DOCUMENT } from '@angular/common';

export interface OverlayConfig {
  data?: unknown;
  panelClass?: string,
  needsCallBackBeforeClose?: boolean,
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  constructor(
    private overlay: Overlay,
    private injector: Injector,
  ) {
  }

  createGlobalOverlay(config?: OverlayConfig): OverlayRef {
    // Globally centered position strategy
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    // Create the overlay with customizable options
    return this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      hasBackdrop: true,
      disposeOnNavigation: true,
      backdropClass: [ 'overlay-backdrop' ],
      panelClass: [ 'overlay-panel', config?.panelClass ?? '' ],
    });
  }

  createConnectedOverlay(elementRef: ElementRef, config?: OverlayConfig, strategy?: PositionStrategy): OverlayRef {
    // Globally centered position strategy
    const positionStrategy = strategy ?? this.overlay
      .position()
      .flexibleConnectedTo(elementRef)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
          offsetY: 8,
        },
      ]);

    // Create the overlay with customizable options
    return this.overlay.create({
      hasBackdrop: false,
      panelClass: [ 'panel-class', config?.panelClass ?? '' ],
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: positionStrategy,
    });
  }

  /**
   * Open a custom component in an overlay
   */
  openComponent<T>(component: ComponentType<T>, config?: OverlayConfig): DialogRef {

    const overlayRef = this.createGlobalOverlay(config);
    // Create dialogRef to return
    const dialogRef = new DialogRef(overlayRef);

    // Create injector to be able to reference the DialogRef from within the component
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: DialogRef, useValue: dialogRef },
        { provide: DIALOG_DATA, useValue: config?.data },
      ],
    });

    // Attach component portal to the overlay
    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);

    const closeEvents = [
      overlayRef.backdropClick(),
      overlayRef.keydownEvents().pipe(filter(event => event.key === 'Escape')),
    ];

    return this.open(dialogRef, closeEvents, config);
  }


  public openDropdown(content: Dropdown, viewContainerRef: ViewContainerRef, elementRef: ElementRef): DialogRef {

    const overlayRef = this.createConnectedOverlay(elementRef);
    return this.openOverlay(content, overlayRef, viewContainerRef);
  }

  public openPopup(content: Popup, viewContainerRef: ViewContainerRef): DialogRef {

    const overlayRef = this.createGlobalOverlay();
    return this.openOverlay(content, overlayRef, viewContainerRef);
  }

  public openOverlay(content: Dropdown | Popup, overlayRef: OverlayRef, viewContainerRef: ViewContainerRef): DialogRef {
  // public openOverlay(content: Dropdown, overlayRef: OverlayRef, viewContainerRef: ViewContainerRef): DialogRef {
    const dialogRef = new DialogRef(overlayRef);

    // Attach component portal to the overlay
    const portal = content.templateRef && viewContainerRef
      ? new TemplatePortal(content.templateRef, viewContainerRef)
      : undefined;
    overlayRef.attach(portal);

    const closeEvents = [
      overlayRef.outsidePointerEvents(),
      overlayRef.keydownEvents().pipe(filter(event => event.key === 'Escape')),
      content.closed.asObservable(),
    ];

    return this.open(dialogRef, closeEvents);
  }

  private open(dialogRef: DialogRef, closeEvents: Observable<unknown>[], config?: OverlayConfig): DialogRef {
    const closeEventsSubscription = merge(...closeEvents)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (config?.needsCallBackBeforeClose) {
          dialogRef.close();
          return;
        }
        dialogRef.closeImmediately();
        closeEventsSubscription.unsubscribe();
      });

    return dialogRef;
  }

  public openCurtain(
    content: Curtain,
    viewContainerRef: ViewContainerRef,
    elementRef: ElementRef,
    config?: { backDoor?: boolean, position?: { [key:string]: string } },
  ): DialogRef {
    // Create dialogRef to return
    const bound = (elementRef as unknown as HTMLElement).getBoundingClientRect();
    const document = this.injector.get(DOCUMENT);

    const overlayRef = this.createConnectedOverlay(elementRef, undefined,
      this.overlay.position()
        .flexibleConnectedTo(elementRef)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'bottom',
            offsetY: 0,
            panelClass: bound.top <= 50 ? 'curtain-offset' : '',
            ...config?.position,
          },
        ]));

    let backDoorHeight = 0;
    if (config?.backDoor) {
      if (document.documentElement.scrollHeight < bound.bottom) {
        backDoorHeight = bound.height - (bound.bottom - document.documentElement.scrollHeight);
      } else if (bound.top < 64) {
        if (bound.top > 0) {
          backDoorHeight = bound.height - (64 - bound.top);
        } else {
          backDoorHeight = bound.height - (Math.abs(bound.top) + 64);
        }
      } else {
        backDoorHeight = bound.height;
      }
    }
    overlayRef.updateSize({
      width: bound.width,
      height: backDoorHeight,
    });

    overlayRef.detachBackdrop();

    return this.openOverlay(content, overlayRef, viewContainerRef);
  }

}
