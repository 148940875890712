export interface ErrorsModel {
  errors: Array<ErrorModel>
}

export interface ErrorModel {
  error_key: string,
  error_message: string,
  error_loc: Array<string>
}

export interface ErrorResponse {
  error: ErrorsModel,
  message: string,
  status: number,
  url: string
}
