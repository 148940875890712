import { Directive, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { getBack } from '@store/router/router.actions';

@Directive({
  selector: '[sensBackButton]',
})
export class BackButtonDirective {

  constructor(
    private store: Store,
  ) { }

  @HostListener('click')
  onClick(): void {
    this.store.dispatch(getBack());
  }
}
