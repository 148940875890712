import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { sessionActions } from './session.actions';
import { authActions } from '@store/auth';
import { map, of, switchMap, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorResponse, Session, SessionResponse } from '@core/models';
import { AuthRepository } from '@core/repositories';
import { CookieService } from '@core/services/cookies/cookie.service';
import { TOKEN_NAME } from '@data/config';
import { Router } from '@angular/router';
import * as postActions from '@store/post/post.actions';
import { routerActions } from '@store/router';

@Injectable({ providedIn: 'root' })
export class SessionEffects {


  constructor(
    private actions: Actions,
    private cookieService: CookieService,
    private authRepository: AuthRepository,
    private router: Router,
  ) { }

  public getAuthTokenSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(
        sessionActions.getAuthSessionSuccess,
        sessionActions.refreshAuthSessionSuccess,
        authActions.verifyCodeSuccess,
        authActions.signEulaSuccess,
      ),
      tap(({ token }) => {
        if (!!token.is_eula_signed) {
          this.cookieService.set(TOKEN_NAME, JSON.stringify(token as Session));
        }
      }),
      map(({ token }) => sessionActions.getAuthTokenSuccess({ token })),
    );
  });

  // public getAuthTokenSuccessNavigate = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(
  //       authActions.verifyCodeSuccess,
  //       authActions.signEulaSuccess,
  //     ),
  //     tap(({ token }) => {
  //       if (!!token.is_eula_signed) {
  //         this.router.navigate(['/']).then();
  //       }
  //     }),
  //   );
  // }, { dispatch: false });

  public getAuthSession = createEffect(() => {
    return this.actions.pipe(
      ofType(sessionActions.getAuthSession),
      switchMap(() => this.authRepository.postAuthSession().pipe(
        map(tokenData => sessionActions.getAuthSessionSuccess({ token: tokenData.data })),
        catchError((error: unknown) => of(sessionActions.getAuthSessionFailure({ error: error as ErrorResponse }))),
      )),
    );
  });

  public refreshAuthSession = createEffect(() => {
    return this.actions.pipe(
      ofType(sessionActions.refreshAuthSession),
      switchMap(() => this.authRepository.postAuthSessionToken().pipe(
        map((tokenData: SessionResponse) => sessionActions.refreshAuthSessionSuccess({ token: tokenData.data })),
        catchError((error: unknown) => of(sessionActions.refreshAuthSessionFailure({ error: error as ErrorResponse }))),
      )),
    );
  });

  public logout = createEffect(() => {
    return this.actions.pipe(
      ofType(sessionActions.logout),
      map(() => sessionActions.removeAuthSession()),
    );
  });

  public clearFeed = createEffect(() => {
    return this.actions.pipe(
      ofType(sessionActions.logout),
      map(() => postActions.clearFeed()),
    );
  });

  public returnToRoot = createEffect(() => {
    return this.actions.pipe(
      ofType(sessionActions.logout),
      map(() => routerActions.navigate({ path: '/' })),
    );
  });

  public removeAuthSession = createEffect(() => {
    return this.actions.pipe(
      ofType(sessionActions.removeAuthSession),
      switchMap(() => this.authRepository.deleteAuthSession().pipe(
        tap(() => this.cookieService.clear(TOKEN_NAME)),
        // tap(() => this.router.navigate(['login'])),
        map(() => sessionActions.removeAuthSessionSuccess()),
        catchError((error: unknown) => of(sessionActions.removeAuthSessionFailure({ error: error as ErrorResponse }))),
      )),
    );
  });
}
