import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthRepository } from '@core/repositories';
import { AuthWebRepository } from '@data/repositories';
import { sessionFeatureKey, sessionReducer, SessionEffects } from '@store/session';


@NgModule({
  imports: [
    StoreModule.forFeature(sessionFeatureKey, sessionReducer),
    EffectsModule.forFeature([ SessionEffects ]),
  ],
  exports: [
    StoreModule,
  ],
  providers: [
    { provide: AuthRepository, useClass: AuthWebRepository },
  ],
})
export class SessionFeatureModule { }
