export interface SelectOption {
  label: string,
  value: string,
  icon?: string,
}

export const emptySelectOption = {
  label: '< empty >',
  value: '',
  icon: '',
};
