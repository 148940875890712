import { Directive, ElementRef, HostListener, Input, ViewContainerRef } from '@angular/core';
import { OverlayService } from '@app/services/dialog/overlay.service';
import { Curtain } from '@private/components/curtain/curtain.component';

@Directive({
  selector: '[sensCurtainTriggerFor]',
})
export class CurtainTriggerForDirective {

  @Input() public sensCurtainTriggerFor?: Curtain;

  @Input() public elementRefInput?: any;

  @Input() public config?: { backDoor?: boolean, position?: { [key:string]: string } };

  @HostListener('click') onClick() {
    this.openCurtain();
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private overlay: OverlayService,
    private elementRef: ElementRef,
  ) {}

  openCurtain(): void {
    if (this.sensCurtainTriggerFor) {
      this.overlay.openCurtain(
        this.sensCurtainTriggerFor,
        this.viewContainerRef ?? this.elementRef,
        this.elementRefInput,
        ({ ...this.config, backDoor: this.config?.backDoor ?? true }),
      );
    }

  }

}
