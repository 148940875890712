/**
 * Sens
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PaymentType {
  name: string;
  type: string;
  logo: string;
  min: number;
  max: number;
  service_fee: number;
  payservice_fee: number;
  iso_codes: Array<string>;
  adr_type?: string;
}
