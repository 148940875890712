import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type IconColor = 'primary' | 'secondary' | 'white' | 'success' | 'info' | 'error';

@Component({
  standalone: true,
  selector: 'sens-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input({ required: true })
  public path = '';

  @Input()
  public hovered = false;

  @Input()
  public color: IconColor | string = 'secondary';

  @Input()
  public set size(value: number) {
    if (value) {
      this.width = this.height = value;
    }
  }

  @Input()
  public width = 24;

  @Input()
  public height = 24;
}
