import { createReducer, on } from '@ngrx/store';
import { AuthStep, ErrorResponse } from '@core/models';
import { sessionActions } from '@store/session';
import {
  createFormGroupState,
  FormGroupState,
  onNgrxForms,
  onNgrxFormsAction,
  setValue,
  SetValueAction,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { minLength, pattern, required } from 'ngrx-forms/validation';
import { authActions } from '@store/auth';

export const authFeatureKey = 'auth';

export interface AuthFormState {
  email: string | undefined,
  code: string | undefined,
  age: boolean
}

export interface AuthState {
  form: FormGroupState<AuthFormState>
  step: number,
  error?: ErrorResponse,
  loading: boolean,
  is_eula_signed?: boolean,
}

export const initialState: AuthState = {
  form: createFormGroupState<AuthFormState>('authForm', {
    email: undefined,
    code: undefined,
    age: false,
  }),
  step: AuthStep.START,
  error: undefined,
  loading: false,
};

const reducer = createReducer(
  initialState,
  onNgrxForms(),
  onNgrxFormsAction(SetValueAction, (state) => {
    return ({
      ...state,
      error: undefined,
    });
  }),
  on(
    authActions.clearState,
    (): AuthState => ({
      ...initialState,
    })),
  on(
    authActions.clearAuthErrors,
    (state): AuthState => ({
      ...state,
      error: undefined,
    })),
  on(
    authActions.getCode,
    (state): AuthState => ({
      ...state,
      loading: true,
      form: setValue(
        state.form,
        {
          ...state.form.value,
          code: undefined,
        }),
    }),
  ),
  on(
    authActions.getCodeSuccess,
    (state): AuthState => ({
      ...state,
      step: AuthStep.CODE,
      loading: false,
    })),
  on(
    authActions.getCodeFailure,
    (state, { error }): AuthState => ({
      ...state,
      error,
      loading: false,
    })),
  on(
    authActions.verifyCode,
    (state): AuthState => ({
      ...state,
      loading: true,
    })),
  on(
    authActions.verifyCodeSuccess,
    (state, { token }): AuthState => ({
      ...state,
      step: token.is_eula_signed ? state.step : AuthStep.EULA,
      loading: false,
    })),
  on(
    authActions.verifyCodeFailure,
    (state, { error }): AuthState => ({
      ...state,
      error,
      loading: false,
    })),
  on(
    //TODO создать auth-action, перемапить removeAuthSession на него и здесь использовать
    sessionActions.removeAuthSession,
    (state): AuthState => {
      const resetCodeForm = setValue(state.form, { ...state.form.value, code: undefined });

      return ({
        ...state,
        step: AuthStep.START,
        form: resetCodeForm,
        error: undefined,
      });
    }),
);

const validateAndUpdateForm = updateGroup<AuthFormState>({
  email: validate(required, pattern(/\S+@\S{2,}\.\S{2,}/)),
  code: validate(required, minLength(6)),
});

export const authReducer = wrapReducerWithFormStateUpdate(
  reducer,
  state => state.form,
  validateAndUpdateForm,
);
