import { Inject, LOCALE_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, EMPTY, finalize, from, map, Observable, of, take } from 'rxjs';
import { EMPTY_STRING } from '@data/const';
import { GetSession, Session } from '@core/models';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { filter, switchMap } from 'rxjs/operators';
import { sessionActions } from '@store/session';
import { FingerprintjsProAngularService } from '@fingerprintjs/fingerprintjs-pro-angular';

export type HeadersType = {
  [key: string | 'Authorization']: string;
};

export class Interceptor {

  public static requestId?: string;

  protected isRefreshingToken = false;

  protected tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(EMPTY_STRING);

  constructor(
    protected store: Store,
    protected actions: Actions,
    protected fingerprintService: FingerprintjsProAngularService,
    @Inject(LOCALE_ID) protected locale: string,
  ) { }

  protected getSession(action: GetSession, request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshingToken) {
      return this.handleTokenRequest(action).pipe(
        take(1),
        switchMap((token) => {
          this.tokenSubject.next(token.access_token);
          return from(this.fingerprintService.getVisitorData())
            .pipe(
              switchMap(visitor => next.handle(request.clone({
                setHeaders: this.setHeaders(visitor.visitorId, token.access_token, visitor.requestId),
              })),
              ),
            );
        }),
        finalize(() => this.isRefreshingToken = false),
      );
    } else {
      return this.tokenSubjectRequest(request, next);
    }
  }

  private handleTokenRequest(action: GetSession): Observable<Session> {
    this.isRefreshingToken = true;
    this.tokenSubject.next(EMPTY_STRING);
    this.store.dispatch(action === GetSession.NEW
      ? sessionActions.getAuthSession()
      : sessionActions.refreshAuthSession(),
    );

    return this.actions.pipe(
      ofType(sessionActions.getAuthTokenSuccess),
      filter((token) => !!token),
      switchMap(({ token }) => token ? of(token) : EMPTY),
    );
  }

  protected tokenSubjectRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.tokenSubject.pipe(
      filter(token => token !== EMPTY_STRING),
      take(1),
      switchMap((token) => from(this.fingerprintService.getVisitorData()).pipe(
        map((visitor) => ({ token, visitor })),
      )),
      switchMap(({ token, visitor }) => next.handle(request.clone({
        setHeaders: this.setHeaders(visitor.visitorId, token, visitor.requestId),
      }))),
    );
  }

  protected setHeaders(visitorId: string | null, token?: string, requestId?: string): HeadersType {
    Interceptor.requestId = Interceptor.uuid();
    let headers = {
      'Accept-language': this.locale,
      'X-Sens-Request-Id': Interceptor.requestId,
    };

    if (visitorId) {
      headers = Object.assign(headers, {
        'X-Sens-Device-Id': visitorId,
      });
    }

    if (requestId) {
      headers = Object.assign(headers, {
        'X-Sens-Bot-Request-Id': requestId,
      });
    }

    if (token) {
      headers = Object.assign(headers, {
        Authorization: `Bearer ${ token }`,
      });
    }
    return headers;
  }

  private static uuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}
