import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
// import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';
// import { ActiveToast } from 'ngx-toastr/toastr/toastr.service';

// export type NotificationConfig = IndividualConfig;
// export type NotificationItem = ActiveToast<unknown>;

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private config: Partial<any> = {
    timeOut: 3000,
    closeButton: false,
    positionClass: 'toast-bottom-center',
  };

  constructor(private toast: ToastrService) {}

  public success(message: string, title?: string, config?: Partial<any>): any {
    return this.toast.success(message, title, { ...this.config, ...config });
  }

  public error(message: string, title?: string, config?: Partial<any>): any {
    return this.toast.error(message, title, { ...this.config, ...config });
  }
}
