import {
  defaultInterpolationFormat,
  I18NEXT_NAMESPACE,
  I18NEXT_SERVICE,
  I18NextLoadResult,
  I18NextModule,
  ITranslationService,
} from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { InitOptions } from 'i18next';
import { APP_INITIALIZER, LOCALE_ID, Provider } from '@angular/core';
import { environment } from '@environments/environment';
import ruCommon from './locales/ru/common.json';
import enCommon from './locales/en/common.json';
import ruError from './locales/ru/error.json';
import enError from './locales/en/error.json';
import ruValidation from './locales/ru/validation.json';
import enValidation from './locales/en/validation.json';
import ruSearch from './locales/ru/search.json';
import enSearch from './locales/en/search.json';
import ruUser from './locales/ru/user.json';
import enUser from './locales/en/user.json';
import ruStream from './locales/ru/stream.json';
import enStream from './locales/en/stream.json';
import ruPost from './locales/ru/post.json';
import enPost from './locales/en/post.json';
import enTranslates from './locales/en.json';
import ruTranslates from './locales/ru.json';
import ruOnboarding from './locales/ru/onboarding.json';
import enOnboarding from './locales/en/onboarding.json';
import ruMedia from './locales/ru/media.json';
import enMedia from './locales/en/media.json';
import ruVerify from './locales/ru/verify.json';
import enVerify from './locales/en/verify.json';

export enum LanguageLocale {
  EN = 'en-US',
  RU = 'ru-RU',
}
export const DEFAULT_LANGUAGE = LanguageLocale.RU;

const i18nextOptions: InitOptions = {
  resources: {
    [LanguageLocale.EN]: {
      ...enTranslates,
      common: enCommon,
      error: enError,
      validation: enValidation,
      search: enSearch,
      user: enUser,
      stream: enStream,
      post: enPost,
      onboarding: enOnboarding,
      media: enMedia,
      verify: enVerify,
    },
    [LanguageLocale.RU]: {
      ...ruTranslates,
      common: ruCommon,
      error: ruError,
      validation: ruValidation,
      search: ruSearch,
      user: ruUser,
      stream: ruStream,
      post: ruPost,
      onboarding: ruOnboarding,
      media: ruMedia,
      verify: ruVerify,
    },
  },
  fallbackLng: {
    'en-US': [ LanguageLocale.EN ],
    'ru-RU': [ LanguageLocale.RU ],
    default: [ DEFAULT_LANGUAGE ],
  },
  // languages: ['ru', 'en'],
  supportedLngs: Object.values(LanguageLocale),
  returnEmptyString: false,
  debug: !environment.production,

  // have a common namespace used around the full app
  defaultNS: 'translations',
  load: 'currentOnly',
  keySeparator: '.', // we use content as keys
  nsSeparator: ':',
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
  },

  // lang detection plugin options
  detection: {
    // order and from where users language should be detected
    order: [ 'querystring', 'htmlTag', 'localStorage', 'navigator' ],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    caches: [ 'localStorage' ],
    excludeCacheFor: [ 'cimode' ], // languages to not persist (cookie, localStorage)
    cookieMinutes: 0,
    cookieDomain: '/',
  },
};

//  Инициализация i18next
export function appInit(i18next: ITranslationService): () => Promise<I18NextLoadResult | void> {
  return () => i18next
    .use(LanguageDetector)
    .init(i18nextOptions);
}

export function localeIdFactory(i18next: ITranslationService): string {
  return i18next.language;
}

export const I18N_PROVIDERS: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [ I18NEXT_SERVICE ],
    multi: true,
  },
  {
    provide: I18NEXT_NAMESPACE,
    useValue: ['translations', 'validationErrors'],
  },
  {
    provide: LOCALE_ID,
    deps: [ I18NEXT_SERVICE ],
    useFactory: localeIdFactory,
  },
];
