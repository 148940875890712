import { Injectable } from '@angular/core';
import { CommonRepository } from '@core/repositories';
import { CentrifugoSettingsResponse, CommonService } from '@api/backend';
import { map, Observable } from 'rxjs';
import { ComplaintListResponse } from '@core/models';
import { SettingsModel } from '@core/models/settings.model';

@Injectable()
export class CommonWebRepository extends CommonRepository {

  constructor(
    private commonService: CommonService,
  ) {
    super();
  }

  public getCommonSettings(): Observable<SettingsModel> {
    try {
      return this.commonService.getCommonSettingsCommonSettingsGet()
        .pipe(map(({ data }) => data));
    } catch {
      throw new Error('Can\'t execute getCommonSettings api2 method');
    }
  }

  public getCommonComplaints(): Observable<ComplaintListResponse> {
    try {
      return this.commonService.getComplaintsCommonComplaintsGet();
    } catch {
      throw new Error('Can\'t execute getCommonComplaints api2 method');
    }
  }

  public getCommonCentrifugo(): Observable<CentrifugoSettingsResponse> {
    try {
      return this.commonService.getCentrifugoSettingsCommonCentrifugoGet();
    } catch {
      throw new Error('Can\'t execute getCommonCentrifugo api2 method');
    }
  }

}
