/**
 * Sens
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Message {
  event: string;
  user_name: string;
  from_user_id: string;
  to_user_id: string;
  dialog_id?: string;
  data?: any | null;
  avatar_url?: string;
  created_at?: any | null;
  message_id: string;
}
