import { createReducer, on } from '@ngrx/store';
import * as LayoutActions from './layout.actions';
export const layoutFeatureKey = 'layout';

export interface LayoutState {
  breakpoint: string | undefined,
}

export const initialState: LayoutState = {
  breakpoint: undefined,
};

export const layoutReducer = createReducer(
  initialState,
  on(
    LayoutActions.setBreakpoint,
    (state, { breakpoint }): LayoutState => ({ ...state, breakpoint }),
  ),
);
