import { DestroyRef, Directive, ElementRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { fromEvent } from 'rxjs';
import { Store } from '@ngrx/store';
import { sessionSelectors } from '@store/session';


@Directive({
  selector: '[sensAuthGuard]',
  standalone: true,
})
export class AuthGuardDirective implements OnInit {
  @Input() public isAuthGuardDisabled = false;

  private userId = inject(Store).selectSignal(sessionSelectors.selectSessionUserId);

  @Output() declineNavigation = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    if (this.isAuthGuardDisabled) {
      return;
    }

    const { nativeElement: element } = this.elementRef;

    fromEvent<MouseEvent | TouchEvent>(element, 'click', { capture: true })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        if (!this.userId()) {
          event.stopPropagation();
          event.preventDefault();
          this.declineNavigation.emit();
        }
      });
  }
}
