import { ChangeDetectionStrategy, Component, effect, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';

import { AuthStep } from '@core/models';
import { authSelectors, authActions } from '@store/auth';
import { DialogRef } from '@app/services/dialog/dialog-ref.class';
import { userSelectors } from '@store/user';

@Component({
  selector: 'sens-auth',
  templateUrl: './auth.component.html',
  styleUrls: [ './auth.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthComponent implements OnInit {

  @ViewChild('cd', { static: false }) public countdown?: CountdownComponent;

  public state = this.store.selectSignal(authSelectors.selectAuthState);

  public canSignEula = false;

  public isCheckEulaSign = false;

  public authStep = AuthStep;

  public authForm = this.store.selectSignal(authSelectors.selectAuthFormState);

  public userData = this.store.selectSignal(userSelectors.selectUserData);

  public countdownConfig: CountdownConfig = {
    leftTime: 5,
    formatDate: date => {
      const minutes = Math.floor((date.date % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((date.date % (1000 * 60)) / 1000);
      return date.date === 0
        ?
        ''
        : `<span class="digit">${ minutes }</span> ${ this.localeId === 'ru-RU' ? 'мин' : 'min' }
         <span class="digit">${ seconds }</span> ${ this.localeId === 'ru-RU' ? 'сек' : 'sec' }`;
    },
  };

  public countdownState?: CountdownEvent;

  constructor(
    private store: Store,
    private dialogRef: DialogRef,
    @Inject(LOCALE_ID) public localeId: string,
  ) {
    effect(() => {
      const user = this.userData();
      if (user) {
        this.dialogRef.closeImmediately();
      }
    });
  }

  public ngOnInit() {
    this.init();
  }

  private init(): void {
    // this.store.dispatch(SettingsActions.getCountry());
  }

  public onGoBack(): void {
    this.countdown?.restart();
    this.store.dispatch(authActions.clearState());
  }

  public getCode(): void {
    this.store.dispatch(authActions.getCode());
  }

  public resendCode(): void {
    this.getCode();
    this.countdown?.restart();
  }

  public verifyCode(): void {
    this.store.dispatch(authActions.verifyCode());
  }

  countdownEvent(event: CountdownEvent) {
    this.countdownState = event;
  }

  public onInput(): void {
    this.store.dispatch(authActions.clearAuthErrors());
  }

  public signEula(): void {
    this.store.dispatch(authActions.signEula());
  }

  public onScrollDown(element: HTMLDivElement): void {
    const container = element;
    if (!this.canSignEula) {
      this.canSignEula = container.offsetHeight + container.scrollTop >= container.scrollHeight;
    }
  }

  public onEulaChange($event: Event): void {
    this.isCheckEulaSign = ($event.target as HTMLInputElement).checked;
  }

  public onCloseModal(): void {
    this.dialogRef.closeImmediately();
  }
}
