import {
  BaseMediaList,
  BaseMediaListResponse,
  BaseMediaResponse, FapResponse,
  MediaResponse as ApiMediaResponse,
  PresignedParams,
} from '@api/backend';

export type Media = ApiMediaResponse;
export type MediaResponse = BaseMediaResponse;
export type MediaList = BaseMediaList;
export type MediaListResponse = BaseMediaListResponse;
export interface MediaRequest {
  isFree?: boolean,
  mediaName?: string,
  mediaDescription?: string,
  video?: Blob,
  preview?: Blob,
  photo1?: Blob,
  photo2?: Blob,
  photo3?: Blob,
  photo4?: Blob,
  photo5?: Blob,
  photo6?: Blob,
}
export type PresignedRequest = PresignedParams;
export enum UploadTypes {
  DEFAULT = 'default',
  PHOTO = 'photo',
  VIDEO = 'video',
}

export interface FapAuthorMedia extends MediaList {
  author_id: string,
}


export type UnitedMediaInfo = Partial<FapResponse & Media>;
