<div class="panel">
  <button
    type="button"
    class="btn btn--icon panel__btn-close"
    (click)="onClose()"
  >
    <sens-icon [size]="24" path="sprite.svg#icon-close"/>
  </button>

  <ng-template [ngIf]="mode === modeType.POST_CREATE_INFO">
    <div class="panel__body">
      <sens-icon [size]="24" path="sprite.svg#icon-circle-outline"/>
      <ng-container *ngTemplateOutlet="verificationMessage"/>
    </div>

    <div class="panel__footer">
      <a
        class="btn btn--primary"
        routerLink="/posts/upload"
        (click)="onClose()"
      >
        {{ 'common:Continue' | i18nextEager }}
      </a>
    </div>
  </ng-template>

  <ng-template [ngIf]="mode === modeType.POST_CREATE_VERIFY">
    <div class="panel__body">
      <sens-icon [size]="24" path="sprite.svg#icon-circle-outline"/>
      <h3 class="title-3">
        {{ 'user:verification.title.start' | i18nextEager }}
      </h3>
      <ng-container *ngTemplateOutlet="verificationMessage"/>
    </div>

    <div class="panel__footer">
      <a
        class="btn btn--primary"
        routerLink="/verify"
        (click)="onVerify()"
      >
        {{ 'user:verification.confirmProfile' | i18nextEager }}
      </a>
    </div>
  </ng-template>

  <ng-template [ngIf]="mode === modeType.USER_FOLLOW_SUCCESS">
    <div class="panel__body">
      <sens-icon [size]="24" path="sprite.svg#icon-circle-outline"/>
      <h3 class="title-3">
        {{ 'user:follow.successTitle' | i18nextEager }}
      </h3>
      <p class="text-1">
        {{ 'user:follow.successMessage' | i18nextEager }}
      </p>
    </div>

    <div class="panel__footer">
      <button
        type="button"
        class="btn btn--primary"
        (click)="onClose()"
      >
        {{ 'user:follow.OK' | i18nextEager }}
      </button>
    </div>
  </ng-template>
</div>

<ng-template #verificationMessage>
  <p class="text-1">
    {{ 'post:createPostMessage.In one publication you can upload' | i18nextEager }}
    <span class="text--info">
      {{ 'post:createPostMessage.1 video' | i18nextEager }}
    </span>
    {{ 'post:createPostMessage.up to' | i18nextEager }}
    <span class="text--info">
      {{ 'post:createPostMessage.50 megabytes' | i18nextEager }}
    </span>
    {{ 'post:createPostMessage.or up to' | i18nextEager }}
    <span class="text--info">
      {{ 'post:createPostMessage.6 photos' | i18nextEager }}
    </span>
  </p>
</ng-template>
