import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthRepository } from '@core/repositories';
import { catchError, switchMap } from 'rxjs/operators';
import { map, of } from 'rxjs';
import { ErrorResponse } from '@core/models';
import { ScryptService } from '@public/auth/services/scrypt/scrypt.service';
import { authActions, authSelectors } from '@store/auth';
@Injectable()
export class AuthEffects {

  public getCode = createEffect( () => {
    return this.actions.pipe(
      ofType(authActions.getCode),
      map(() => authActions.getScryptChallenge()),
    );
  });

  public getScryptChallenge = createEffect( () => {
    return this.actions.pipe(
      ofType(authActions.getScryptChallenge),
      switchMap(() => this.authRepository.getAuthChallenge().pipe(
        map((challenge) => authActions.getScryptChallengeSuccess({ challenge: challenge.data })),
        catchError((error: unknown) => of(authActions.getScryptChallengeFailure({ error: error as ErrorResponse }))),
      )),
    );
  });

  public getScryptChallengeSuccess = createEffect( () => {
    return this.actions.pipe(
      ofType(authActions.getScryptChallengeSuccess),
      switchMap(({ challenge }) => this.scryptService.scrypt(challenge).pipe(
        map((hash) => authActions.getScryptHashSuccess({ hash })),
        catchError((error: unknown) => of(authActions.getScryptHashFailure({ error: error as ErrorResponse }))),
      )),
    );
  });

  public getScryptHashSuccess = createEffect( () => {
    return this.actions.pipe(
      ofType(authActions.getScryptHashSuccess),
      concatLatestFrom(() => this.store.select(authSelectors.selectAuthFormState)),
      switchMap(([{ hash }, { controls: { email } }]) => this.authRepository.postAuthEmail({ hash, email: email.value ?? '' }).pipe(
        map(() => authActions.getCodeSuccess()),
        catchError((error: unknown) => of(authActions.getCodeFailure({ error: error as ErrorResponse }))),
      )),
    );
  });

  public verifyCode = createEffect( () => {
    return this.actions.pipe(
      ofType(authActions.verifyCode),
      concatLatestFrom(() => this.store.select(authSelectors.selectAuthFormState)),
      switchMap(([ , { controls: { email, code } } ]) =>
        this.authRepository.postAuthEmailVerify({
          code: String(code.value),
          email: String(email.value),
        }).pipe(
          map((tokenData) => authActions.verifyCodeSuccess({ token: tokenData.data })),
          catchError((error: unknown) => of(authActions.verifyCodeFailure({ error: error as ErrorResponse }))),
        )),
    );
  });

  public signEula = createEffect(() => {
    return this.actions.pipe(
      ofType(authActions.signEula),
      switchMap(() => this.authRepository.postAuthSignEula().pipe(
        map((tokenData) => authActions.signEulaSuccess({ token: tokenData.data })),
        catchError((error: unknown) => of(authActions.signEulaFailure({ error: error as ErrorResponse }))),
      )),
    );
  });

  constructor(
    private actions: Actions,
    private store: Store,
    private authRepository: AuthRepository,
    private scryptService: ScryptService,
  ) { }
}
