import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {

  copy(path: string): void {
    try {
      navigator.clipboard.writeText(`${ location.host }/${ path }`)
        .then()
        .catch();
      // eslint-disable-next-line no-empty
    } catch {
    }
  }
}
